import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import HeaderTop from '../../Header/HeaderTop'
import Header from '../../Header/Header'

import Footer from '../../Footer/Footer'
import Intl from '../../Intl'

const ResetPasswordAcknowledgementPage = props => {
    return (
        <>
			<HeaderTop />
			<Header />
            <section className="text-gray-100 body-font bg-gray-400 py-4">
                <div className="container px-5 py-2 mx-auto flex flex-wrap items-center">
                    <div className="lg:w-2/6 md:w-1/2 mx-auto rounded-lg px-8 py-4 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-white border border-gray-300 shadow">

                        <h1 className="text-xl md:text-2xl font-bold leading-tight"><Intl id="resetpassword" /> </h1>
                        <p className="py-2"> You can login with your new credentials! </p>


                        <div className="text-center">
                                <p className="flex mb-2 leading-relaxed justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-green-700 w-16" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                </p>
                                <p className="text-md font-bold text-green-700 mt-2 mb-8">
                                    Password Updated Successfully
                                </p>
                                
                                <Link to={"/app/login"} className="text-sm font-semibold text-blue-700 hover:text-blue-900 focus:text-blue-700">Go to Login</Link>
                            </div>
                            

                    </div>
                </div>
            </section>            

			<Footer />            
        </>
    )
}

ResetPasswordAcknowledgementPage.propTypes = {

}

export default ResetPasswordAcknowledgementPage
