import React from 'react';
import { useState, useContext } from 'react';

import Modal from '../../Core/Modal/Modal';
import Intl from '../../Intl';

import GlobalContext from '../../../context/GlobalContext';
import { navigate } from '@reach/router';
import PaymentModel from '../../Core/PaymentModel';

function LightUser() {
  const gContext= useContext(GlobalContext);
  const {subscriptionPlans} = useContext(GlobalContext);

  // Modal status
  const [isOpen, SetIsOpen] = useState(false);

  // Close Modal function
  const closeModal = () => SetIsOpen(false);

  return (
    <div className='bg-cyan-600 h-full p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden'>
      <span className='bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl'>
      <Intl id="popular"></Intl>
      </span>
      <h2 className='text-sm tracking-widest title-font mb-1 font-medium'>
        {/* <Intl id="lightuser"></Intl> */}
        {subscriptionPlans?.results[1]?.name}
      </h2>
      <h1 className='text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b-2 border-gray-900'>
        <span>{subscriptionPlans?.results[1]?.amount}</span>
        <span className='text-lg ml-1 font-normal text-gray-900'>/{subscriptionPlans?.results[1]?.duration}</span>
      </h1>

      {
         subscriptionPlans?.results[1]?.feature_list?.map( feature => {
          return (
              <p className='flex items-center text-gray-900 mb-2'>
                <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
                  <svg
                    fill='none'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2.5'
                    className='w-3 h-3'
                    viewBox='0 0 24 24'
                  >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>
                </span>
                {/* <Intl id="lightuserfeaturefirst"></Intl> */}
                {feature.name}
              </p>
          )
         })
      }

      {/* <p className='flex items-center text-gray-900 mb-2'>
        <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
          <svg
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2.5'
            className='w-3 h-3'
            viewBox='0 0 24 24'
          >
            <path d='M20 6L9 17l-5-5'></path>
          </svg>
        </span>
        <Intl id="lightuserfeaturesecond"></Intl>
      </p>
      <p className='flex items-center text-gray-900 mb-2'>
        <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
          <svg
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2.5'
            className='w-3 h-3'
            viewBox='0 0 24 24'
          >
            <path d='M20 6L9 17l-5-5'></path>
          </svg>
        </span>
        <Intl id="lightuserfeaturethird"></Intl>
      </p>

      <p className='flex items-center text-gray-900 mb-6'>
        <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
          <svg
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2.5'
            className='w-3 h-3'
            viewBox='0 0 24 24'
          >
            <path d='M20 6L9 17l-5-5'></path>
          </svg>
        </span>
        <Intl id="lightuserfeaturefourth"></Intl>
      </p> */}
      <button
        onClick={() => {
          if (!gContext.isLoggedIn) {
            navigate('/app/login');
          } else {
            SetIsOpen(true);
          }
        }}
        className='flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-900 rounded transition ease-out'
      >
        <Intl id="buynow"></Intl>
        <svg
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          className='w-4 h-4 ml-auto'
          viewBox='0 0 24 24'
        >
          <path d='M5 12h14M12 5l7 7-7 7'></path>
        </svg>
      </button>

      {/* Payment Model */}
      <PaymentModel open={isOpen} close={closeModal} title={'Choose your payment gateway'} amount={subscriptionPlans?.results[1]?.amount} />
      <p className='text-xs text-gray-900 mt-3'>

       <Intl id="lightuserExplanation"></Intl>
      </p>
    </div>
  );
}

export default LightUser;
