import React, { useState, useEffect, useRef } from 'react';
//import { useLocation, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { useLocation, useNavigate } from '@reach/router';
// import Intl from '../Intl';
// import Intl from '../../Intl'
import logoImage from '../../assets/images/logo26.png';
import useTranslate from '../../hooks/useTranslate';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const [translate] = useTranslate();
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  let navigate = useNavigate();

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  const onClicktextForgeryDetection = () => {

    //history.push("/app/forgery-detection");
    //navigate('/app/forgery-detection')
    window.location = "/app/forgery-detection"
    
  }
  const onClickScrumMaster = () => {

    //history.push("/app/forgery-detection");
    //navigate('/app/forgery-detection')
    window.location = "/app/scrummaster"
    
  }
  const onClickFaceForgery = () => {
    
    window.location = "/app/face-forgery-detection"
    
  }
  const onClickSignatureForgery = () => {
    
    window.location = "/app/signature-forgery-detection"

  }

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target))
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 opacity-100`}
        aria-hidden='true'
      ></div>

      {/* Sidebar */}
      <div
        id='sidebar'
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64  flex-shrink-0 bg-gray-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Sidebar header */}
        <div className='flex justify-between mb-10 pr-3 sm:px-2'>
          {/* Close button */}
          <button
            ref={trigger}
            className='lg:hidden text-gray-500 hover:text-gray-400'
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
          >
            <span className='sr-only'>Close sidebar</span>
            <svg
              className='w-6 h-6 fill-current'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z' />
            </svg>
          </button>
          {/* Logo */}
          <Link to='/' className='block justify-center'>
            <img alt='AVermass' className='block h-auto w-24  mx-12' src={logoImage} />
          </Link>
        </div>

        {/* Links */}
        <div className='space-y-8'>
          {/* Pages group */}
          <div>
            <h3 className='text-xs uppercase text-gray-500 font-semibold pl-3'>
              <span className='hidden lg:hidden 2xl:hidden text-center w-6' aria-hidden='true'>
                •••
              </span>

              {/* <span className="block 2xl:block ">Pages</span> */}

              <span className=' 2xl:block hidden'>Pages</span>
            </h3>
            <ul className='mt-3'>
              {/* Dashboard */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-gray-900'
                }`}
              >
                

                  <Link
                    to='/app/dashboard'
                    
                    className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
                      pathname === '/' && 'hover:text-gray-200'
                    }`}
                    
                  >
                    <div className='flex items-center'>
                      <svg className='flex-shrink-0 h-6 w-6' viewBox='0 0 24 24'>
                        <path
                          className={`fill-current text-gray-400 ${
                            pathname === '/' && '!text-indigo-500'
                          }`}
                          d='M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z'
                        />
                        <path
                          className={`fill-current text-gray-600 ${
                            pathname === '/' && 'text-indigo-600'
                          }`}
                          d='M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z'
                        />
                        <path
                          className={`fill-current text-gray-400 ${
                            pathname === '/' && 'text-indigo-200'
                          }`}
                          d='M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z'
                        />
                         <path
                          className={`fill-current text-gray-400 ${
                            pathname === '/' && 'text-indigo-200'
                          }`}
                          d='M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z'
                        />
                      </svg>
                      <span className='text-sm font-medium ml-3  duration-200'>
                        {translate('dashboard')}
                      </span>
                    </div>
                  </Link>

                
              </li>

             

              {/* Text Forgery  Detection */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 hover:text-gray-900 ${
                  pathname.includes('analytics') && 'bg-gray-900'
                }`}
              >
                <Button
                  onClick={() => onClicktextForgeryDetection()}
                >
                  <Link
                    //to='/app/forgery-detection'

                    activeClassName='border-b-2 border-blue-700'
                    className={`block text-gray-200 hover:text-gray-900 truncate transition duration-150 ${
                      pathname.includes('analytics') && 'hover:text-gray-200'
                    }`}
                    //
                    
                  >
                    <div className='flex items-center'>
                      <svg className='flex-shrink-0 h-6 w-6' viewBox='0 0 24 24'>
                        {/* <path className={`fill-current text-gray-600 ${pathname.includes('analytics') && 'text-indigo-500'}`} d="M0 20h24v2H0z" /> */}

                        <path
                          className={`fill-current text-gray-400  ${
                            pathname.includes('analytics') && 'text-indigo-300'
                          }`}
                          d='M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z'
                        />
                      </svg>
                      <span  className='text-sm font-medium ml-3  duration-200'>
                        {translate('textForgeryDetection')}
                      </span>
                    </div>
                  </Link>
                </Button>
              </li>

              {/* Face forgery detection */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('analytics') && 'bg-gray-900'
                }`}
              >
                <Button
                  onClick={() => onClickFaceForgery()}
                >

                  <Link
                    //to='/app/face-forgery-detection'
                    activeClassName='border-b-2 border-blue-500'
                    className={`block text-gray-200 hover:text-gray-900 truncate transition duration-150 ${
                      pathname.includes('analytics') && 'hover:text-gray-200'
                    }`}
                    //onClick={onClickGeneral()}
                  >
                    <div className='flex items-center'>
                      <svg className='flex-shrink-0 h-6 w-6' viewBox='0 0 24 24'>
                        {/* <path className={`fill-current text-gray-600 ${pathname.includes('analytics') && 'text-indigo-500'}`} d="M0 20h24v2H0z" /> */}
                        <path
                          className={`fill-current text-gray-400 ${
                            pathname.includes('analytics') && 'text-indigo-300'
                          }`}
                          d='M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z'
                          />
                      </svg>
                      <span className='text-sm font-medium ml-3  duration-200'>
                        {translate('FaceForgeryDetection')}
                      </span>
                    </div>
                  </Link>
                </Button>
              </li>

              {/* Signature forgery detection */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('analytics') && 'bg-gray-900'
                }`}
              >
                <Button
                  onClick={() => onClickSignatureForgery()}
                >
                  <Link
                    //to='/app/signature-forgery-detection'
                    activeClassName='border-b-2 border-blue-500'
                    className={`block text-gray-200 hover:text-gray-900 truncate transition duration-150 ${
                      pathname.includes('analytics') && 'hover:text-gray-200'
                    }`}
                  >
                    <div className='flex items-center'>
                      <svg className='flex-shrink-0 h-6 w-6' viewBox='0 0 24 24'>
                        {/* <path className={`fill-current text-gray-600 ${pathname.includes('analytics') && 'text-indigo-500'}`} d="M0 20h24v2H0z" /> */}
                        <path
                          className={`fill-current text-gray-400 ${
                            pathname.includes('analytics') && 'text-indigo-300'
                          }`}
                          d='M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z'
                        />
                      </svg>
                      <span className='text-sm font-medium ml-3 flex duration-200'>
                        {translate('SignatureForgeryDetection')}
                      </span>
                    </div>
                  </Link>
                </Button>
              </li>

               {/* Scrum Master  */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('analytics') && 'bg-gray-900'
                }`}
              >
                <Button
                  onClick={() => onClickScrumMaster()}
                >

                  <Link
                    //to='/app/signature-forgery-detection'
                    activeClassName='border-b-2 border-blue-500'
                    className={`block text-gray-200 hover:text-gray-900 truncate transition duration-150 ${
                      pathname.includes('analytics') && 'hover:text-gray-200'
                    }`}
                  >
                    <div className='flex items-center'>
                      <svg className='flex-shrink-0 h-6 w-6' viewBox='0 0 24 24'>
                        <path className={`fill-current text-gray-600 ${pathname.includes('analytics') && 'text-indigo-500'}`} d="M0 20h24v2H0z" />
                        <path
                          className={`fill-current text-gray-400 ${
                            pathname.includes('analytics') && 'text-indigo-300'
                          }`}
                          d='M4 18h2a1 1 0 001-1V8a1 1 0 00-1-1H4a1 1 0 00-1 1v9a1 1 0 001 1zM11 18h2a1 1 0 001-1V3a1 1 0 00-1-1h-2a1 1 0 00-1 1v14a1 1 0 001 1zM17 12v5a1 1 0 001 1h2a1 1 0 001-1v-5a1 1 0 00-1-1h-2a1 1 0 00-1 1z'
                        />
                      </svg>
                      <span className='text-sm font-medium ml-3 flex duration-200'>
                        {translate('ScrumMaster')}
                      </span>
                    </div>
                  </Link>
                </Button>
              </li>



            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
      </div>
    </div>
  );
};

export default Sidebar;
