import React, { Fragment, useContext } from 'react';
// import { Link } from 'react-router-dom';
import { Link } from 'gatsby';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Intl from '../Intl';

import GlobalContext from '../../context/GlobalContext'
import AuthServices from '../../services/auth/AuthServices'

// import GlobalContext from '../../context/GlobalContext'
// import AuthServices from '../../services/auth/AuthServices'

const UserMenu = () => {

	const gContext = useContext(GlobalContext)
	// debugger

    const handleLogout = (e) => {
        e.preventDefault()
        const data = { refresh: gContext.refreshToken };
        gContext.logout()
        AuthServices.logout(data).then((response) => {
            console.log(response)
            gContext.logout();
        }).catch((error) => {
            console.log(error.response.data)
            gContext.logout();
        });        
    }	
	return (
		<div className="relative inline-flex">

			<Menu as="div" className="relative inline-block text-left">
				<div>
					<Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-100">
						{  gContext.userProfile && gContext.userProfile.username && gContext.userProfile.username }
						<ChevronDownIcon
							className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
							aria-hidden="true"
						/>
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 w-44 mt-2 origin-top-right text-right text-black bg-gray-200 divide-y divide-gray-300 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="px-1 py-1 ">
							<Menu.Item>
								<Link to={"/app/change-password"} className="group flex items-center w-full flex  px-2 py-2 text-sm hover:bg-avermass-blue-500 hover:text-gray-100">
									<Intl id="changedPassword" />
								</Link>
							</Menu.Item>
						</div>
						<div className="px-1 py-1 ">
							<Menu.Item>
								<Link to={"/app/deactivate"} className="group flex items-center w-full px-2 py-2 text-sm hover:bg-avermass-blue-500 hover:text-gray-100">
									<Intl id="deactiveAccount" />
								</Link>
							</Menu.Item>
						</div>
						<div className="px-1 py-1 ">
							<Menu.Item>
								<button className="group flex items-center w-full px-2 py-2 text-sm font-semibold hover:bg-avermass-blue-500 hover:text-gray-100"
										onClick={e => handleLogout(e)}	>
									<Intl id="logout" />
								</button>
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
			</Menu>


			{/* <button
				ref={trigger}
				className="inline-flex justify-center items-center group"
				aria-haspopup="true"
				onClick={() => setDropdownOpen(!dropdownOpen)}
				aria-expanded={dropdownOpen}
			>
				Hello User,
				<div className="flex items-center truncate">
					<span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">Logout.</span>
					<svg className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400" viewBox="0 0 12 12">
						<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
					</svg>
				</div>
			</button> */}

			{/* <Transition
				className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
				show={dropdownOpen}
				enter="transition ease-out duration-200 transform"
				enterStart="opacity-0 -translate-y-2"
				enterEnd="opacity-100 translate-y-0"
				leave="transition ease-out duration-200"
				leaveStart="opacity-100"
				leaveEnd="opacity-0"
			>
				<div
					ref={dropdown}
					onFocus={() => setDropdownOpen(true)}
					onBlur={() => setDropdownOpen(false)}
				>
					<ul>
						<li>
							<Link
								className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
								to="/"
								onClick={() => setDropdownOpen(!dropdownOpen)}
							>
								Settings
							</Link>
						</li>
						<li>
							<Link
								className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
								to="/"
								onClick={() => setDropdownOpen(!dropdownOpen)}
							>
								Sign Out
							</Link>
						</li>
					</ul>
				</div>
			</Transition> */}
		</div>
	)
}

export default UserMenu;