import React, { useState, useRef } from 'react'
// import { Link } from 'gatsby'
import Dropzone from 'react-dropzone'
import SignatureForgeryDetectionService from '../../../services/signatureForgeryDetection/SignatureForgeryDetectionServices'
// import { navigate } from '@reach/router'
import Intl from '../../Intl'

const FileUploader = () => {

    const MIN_FILE_SIZE = 1000;   // 1KB
    const MAX_FILE_SIZE = 10000000;  // 10MB
    const [sourceFile, setSourceFile] = useState(null);
    const [sourceFilePreview, setSourceFilePreview] = useState(null);
    const [testDataFile, setTestDataFile] = useState(null);
    const [testDataFilePreview, setTestDataFilePreview] = useState(null);
    const [forceScan, setForceScan] = useState(false);
    const [selectedExtensions, setSelectedExtensions] = useState(['.jpg', '.png', '.jpeg']);
    const [showScanInfo, setShowScanInfo] = useState(false);

    const [isUploading, setIsUploading] = useState(false);
    const [slug, setSlug] = useState(null);
    const [isFetchingResult, setIsFetchingResult] = useState(false);
    const [result, setResult] = useState(false);
    const [threshold, setThreshold] = useState(null);
    const [distance, setDistance] = useState(null);
    let intervalTimer  = useRef(null)

    const handleUpdateForceScan = (isChecked) => {
        setForceScan(isChecked)
    }
    const onSourceFileDrop = (acceptedFiles) => { 
        setSourceFile(acceptedFiles[0]) 
        setSourceFilePreview(URL.createObjectURL(acceptedFiles[0]))
    }
    const onTestDataFileDrop = (acceptedFiles) => { 
        setTestDataFile(acceptedFiles[0]) 
        setTestDataFilePreview(URL.createObjectURL(acceptedFiles[0]))
    }

    const sizeInMb = (sizeInBytes) => { return (sizeInBytes / (1000*1000)).toFixed(2); }
    const sizeInKb = (sizeInBytes) => { return (sizeInBytes / (1000)).toFixed(2); }
    const handleUploadFile = () => {
        if(isUploading || isFetchingResult){
            return false
        }

        if(!sourceFile){
            alert("Please select a source file to continue upload and forgery detection process")
            return false
        }            
        if(!testDataFile){
            alert("Please select a test data file to continue upload and forgery detection process")
            return false
        }            

        if(sourceFile.size < MIN_FILE_SIZE){
            alert(`Ensure source file size is greater than ${sizeInKb(MIN_FILE_SIZE)} KB or ${MIN_FILE_SIZE} Bytes`)
            return false
        }            
        if(testDataFile.size < MIN_FILE_SIZE){
            alert(`Ensure test data file size is greater than ${sizeInKb(MIN_FILE_SIZE)} KB or ${MIN_FILE_SIZE} Bytes`)
            return false
        }            

        if(sourceFile.size > MAX_FILE_SIZE){
            alert(`Ensure source file size is lesser than ${sizeInMb(MAX_FILE_SIZE)} MB`)
            return false
        }            
        if(testDataFile.size > MAX_FILE_SIZE){
            alert(`Ensure test data file size is lesser than ${sizeInMb(MAX_FILE_SIZE)} MB`)
            return false
        }            

        setIsUploading(true)

        var sourceFileName = sourceFile.name;
        var sourceFileExtension = sourceFileName.split('.').pop();

        var testDataFileName = testDataFile.name;
        var testDataFileExtension = testDataFileName.split('.').pop();

        if(selectedExtensions.indexOf("."+sourceFileExtension) < 0){
            alert("."+sourceFileExtension+" source file is not allowed for this upload process")
            return false
        }
        if(selectedExtensions.indexOf("."+testDataFileExtension) < 0){
            alert("."+testDataFileExtension+" test data file is not allowed for this upload process")
            return false
        }

        const formData = new FormData();
        formData.append('uploaded_file_source',sourceFile)
        formData.append('uploaded_file_test_data',testDataFile)
        formData.append('force_scan', forceScan)
        formData.append('selected_file_extensions',selectedExtensions)
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        console.log(formData)
        
        SignatureForgeryDetectionService.uploadFile(formData).then((response) => {
            console.log(response.slug)
            setSlug(response.slug)
            setIsFetchingResult(true)
            setIsUploading(false)
            // self.checkResult()
            checkResult(response.slug)
        }).catch((error) => {
            // console.log(error)
            // console.log(error.response.data)
            setIsUploading(false)
            if (error.response) {
                if(error.response.status === 402){
                    alert("Your monthly free limit exceeds")
                }
            }            
        });
    }

    const handleRemoveSourceFile = () => { setSourceFile(null); setSourceFilePreview(null) }
    const handleRemoveTestDataFile = () => { setTestDataFile(null); setTestDataFilePreview(null) }
    const handleRemoveFile = () => {
        setSourceFile(null);
        setTestDataFile(null);
        setShowScanInfo(false)
        // clearInterval(intervalTimer.current);
    }

    const checkResult = (slug) => {
        intervalTimer.current = setInterval(() => { 
            SignatureForgeryDetectionService.getResult(slug).then((response) => {
                console.log(response)
                if(response.status !== 'IN_JOB_QUEUE'){
                    clearInterval(intervalTimer.current);
                    setThreshold(response.threshold)
                    setDistance(response.distance)
                    setResult(response.result)
                    setIsFetchingResult(false)
                }
            }).catch((error) => {
                console.log(error)
            });
        }, 6000);
    }
    const handleResetUpload = () => {
        setSourceFile(null)
        setSourceFilePreview(null)
        setTestDataFile(null)
        setTestDataFilePreview(null)
        setForceScan(false)
        setSelectedExtensions(['.jpg', '.png', '.jpeg'])
        setShowScanInfo(false)
        setIsUploading(false)
        setSlug(null)
        setIsFetchingResult(false)
        setResult(false)
        setDistance(null)
    }

    
    return (
        <>
      

            <div className="w-full p-6 rounded-lg shadow-xl bg-gray-50">
                { !showScanInfo && 
                    <div className="flex w-full space-x-3">
                        <div className="w-1/2 ">
                            <h3 className="p-3 font-bold"><Intl id="sourceImage" /></h3>
                            { !sourceFilePreview && 
                            <div className="flex items-center justify-center w-full">
                                <label className="flex flex-col w-full h-32 border-4 border-blue-300 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                    <div className="flex flex-col items-center justify-center pt-7">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700 group-hover:text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                        </svg>
                                        <p className="pt-1 text-sm tracking-wider text-gray-700 group-hover:text-gray-900">
                                            <Intl id="AttachAFile" />
                                        </p>
                                    </div>

                                    
                                    <Dropzone onDrop={acceptedFiles => onSourceFileDrop(acceptedFiles)} >
                                        {({ getRootProps, getInputProps}) => {

                                            return(
                                                <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                </div>
                                                </section>
                                            )
                                        }}
                                    </Dropzone>
                                    

                                    

                                    
                                </label>
                            </div>
                            }
                            { sourceFilePreview && 
                                <>
                                    <div className="w-full mb-3">
                                        <img src={sourceFilePreview}  />
                                    </div>
                                    <div>
                                        <button className="px-4 py-2 text-white bg-gray-800 rounded shadow-xl" onClick={handleRemoveSourceFile} > <Intl id="clear" /></button>
                                    </div>
                                </>
                            }
                        </div>


                        <div className="w-1/2">
                            <h3 className="p-3 font-bold"><Intl id="testImage" /></h3>
                            { ! testDataFilePreview && 
                                <div className="flex items-center justify-center w-full">
                                    <label className="flex flex-col w-full h-32 border-4 border-blue-300 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                        <div className="flex flex-col items-center justify-center pt-7">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-700 group-hover:text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                            </svg>
                                            <p className="pt-1 text-sm tracking-wider text-gray-700 group-hover:text-gray-900">
                                            <Intl id="AttachAFile" />
                                            </p>
                                        </div>

                                        <Dropzone onDrop={acceptedFiles => onTestDataFileDrop(acceptedFiles)} >
                                            {({ getRootProps, getInputProps}) => {

                                                return(
                                                    <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                    </div>
                                                    </section>
                                                )
                                            }}
                                        </Dropzone>

                                        
                                    </label>
                                </div>
                            }
                            { testDataFilePreview && 
                                <>
                                    <div className="w-full mb-3">
                                        <img src={testDataFilePreview}  />
                                    </div>
                                    <div>
                                        <button className="px-4 py-2 text-white bg-gray-800 rounded shadow-xl" onClick={handleRemoveTestDataFile} > <Intl id="clear" /></button>
                                    </div>
                                </>
                            }

                        </div>



                    </div>


                                
                }
                { showScanInfo && 

                    <>
                        <div className="flex-row justify-center  p-2">
                            <div className="flex justify-center"> 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </div>
                        
                            <p className="flex justify-center text-gray-800 text-left dark:text-white font-bold my-4">  
                                { sourceFile && sourceFile.name } 
                            </p>
                            <p>
                                Size : { sourceFile && sourceFile.size } Bytes
                            </p>
                        </div>
                        <div className="flex-row justify-center  p-2">
                            <div className="flex justify-center"> 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </div>
                        
                            <p className="flex justify-center text-gray-800 text-left dark:text-white font-bold my-4">  
                                { testDataFile && testDataFile.name } 
                            </p>
                            <p>
                                Size : { testDataFile && testDataFile.size } Bytes
                            </p>
                        </div>
                    </>

                }
                { !slug && 
                    <div className="flex justify-center p-2">
                        <label className="inline-flex items-center mt-3">
                            <input name="force_scan" type="checkbox" className="form-checkbox h-5 w-5 text-green-600" onChange={e => handleUpdateForceScan(e.target.checked) }  />
                            <span className="ml-2 text-gray-700"><Intl id="forceScan" /> </span>
                        </label>
                    </div>
                }    
                <div className="flex-row justify-center p-2">
                    { slug && 
                        <p className="text-2xl font-bold pt-6"> <Intl id="forgeryDetectionResult" /> </p>
                    }
                    { isFetchingResult &&
                        <div className="flex justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="84" cy="50" r="10" fill="#e15b64">
                                    <animate attributeName="r" repeatCount="indefinite" dur="0.25s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
                                    <animate attributeName="fill" repeatCount="indefinite" dur="1s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64" begin="0s"></animate>
                                </circle><circle cx="16" cy="50" r="10" fill="#e15b64">
                                <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
                                </circle><circle cx="50" cy="50" r="10" fill="#f47e60">
                                <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.25s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.25s"></animate>
                                </circle><circle cx="84" cy="50" r="10" fill="#f8b26a">
                                <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5s"></animate>
                                </circle><circle cx="16" cy="50" r="10" fill="#abbd81">
                                <animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.75s"></animate>
                                <animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.75s"></animate>
                                </circle>
                            </svg>       
                        </div>    
                    }           
                    
                </div>    
                { result && 
                    <>
                        <div className="flex-row justify-center p-2"> 
                            <p> {<Intl id="result" />} : { result } </p>
                            <p> {<Intl id="threshold" />} : { threshold } </p>
                            <p> {<Intl id="distance" />} : { distance } </p>
                        </div>
                        <div>
                            <button className="pl-8 pr-8 py-2 text-white bg-blue-500 rounded shadow-xl" onClick={e => handleResetUpload() }>
                            <Intl id="scanAnotherFile" />
                            </button>                            
                        </div>
                    </>
                    
                }            
                { !slug &&
                    <div className="flex justify-center p-2 space-x-2">
                    
                        <button className="flex pl-4 pr-8 py-2 text-white bg-blue-500 hover:bg-blue-900 rounded shadow-xl" onClick={e => handleUploadFile()}>
                            { isUploading && 
                                <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            }
                            <Intl id="UploadAndScan" />
                        </button>
                    
                        { showScanInfo && 
                            <button className=" px-8 py-2 text-white bg-red-500 rounded shadow-xl" onClick={e => handleRemoveFile()}><Intl id="clear" /></button>
                        }
                    </div>
                }
                <div>
                
                </div>
            </div>


        </>
    )
}


export default FileUploader

