// import React, { useEffect } from 'react'
import React  from 'react'
import { useState, useContext } from 'react'
import Intl from '../../Intl'
import Modal from '../../Core/Modal/Modal'

import GlobalContext from '../../../context/GlobalContext'
import { navigate } from '@reach/router'



import GooglePay from '../../Core/PaymentButton/GooglePay/GooglePay'
import PayPal from '../../Core/PaymentButton/PayPal/PayPal'
// import AuthServices from '../../../services/auth/AuthServices'

function CorporateLargeUser() {

        const gContext = useContext(GlobalContext);
        const {subscriptionPlans} = useContext(GlobalContext);

        // Modal status
        const [isOpen, SetIsOpen] = useState(false)

        // Close Modal function
        const closeModal = () => SetIsOpen(false)

        // useEffect(() => {
        //     // AuthServices.paymentIntegration().then(res => {
        //     //     console.log("paymentintegration",res);
        //     //     setSubscriptionPlans(res);
        //     // }).catch(err => {
        //     //     console.log(err);
        //     // });
        //     console.log("ccc",subscriptionPlans);
    
        // },[]);

        
        
    return (
        <>
            <div className="bg-blue-800 h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col  overflow-hidden">
                <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    {/* <Intl id="corporatelargeuser"></Intl> */}
                    Corporate {subscriptionPlans?.results[2]?.name}
                </h2>
                <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b-2 border-gray-900">
                    <span>{subscriptionPlans?.results[2]?.amount}</span>
                    <span className="text-lg ml-1 font-normal text-gray-900">
                        /{subscriptionPlans?.results[0]?.duration}
                        {/* <Intl id="/mo"></Intl> */}
                    </span>
                </h1>
            {
                subscriptionPlans?.results[2]?.feature_list?.map( feature => {
                    return (

                        <p className="flex items-center text-gray-900 mb-2">
                            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2.5"
                                    className="w-3 h-3"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                            </span>
                            {/* <Intl id="corporatelargeuserfeaturefirst"></Intl> */}
                            {feature.name}
                        </p>
                    )
                    })
            }

                {/* <p className="flex items-center text-gray-900 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="corporatelargeuserfeaturesecond"></Intl>
                </p>
                <p className="flex items-center text-gray-900 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="corporatelargeuserfeaturethird"></Intl>
                </p>

                <p className="flex items-center text-gray-900 mb-6">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="corporatelargeuserfeaturefourth"></Intl>
                </p> */}
                <button
                    onClick={() => {
                        if (!gContext.isLoggedIn) {
                            navigate('/app/login');
                        } else {
                            SetIsOpen(true);
                        }
                    }}
                    className="flex items-center mt-auto text-black bg-gray-100 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 hover:text-gray-100 rounded transition ease-out"
                >
                    <Intl id="buynow"></Intl>
                    <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                    >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </button>

                {/* Payment button */}
                <Modal open={isOpen} close={closeModal}>

                    <GooglePay amount={subscriptionPlans?.results[2]?.amount} currency="USD" />
                    <PayPal amount={subscriptionPlans?.results[2]?.amount} currency="USD" />

                </Modal>


                <p className="text-xs text-gray-900 mt-3">
                    <Intl id="corporatelargeuserExplanation"></Intl>
                </p>
            </div>

        </>
    )
}

export default CorporateLargeUser