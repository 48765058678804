import React, { useEffect } from 'react'
import { useContext } from 'react'
import GlobalContext from '../../../context/GlobalContext';
import { navigate } from '@reach/router'
import Intl from '../../Intl';


function Trial() {

    const gContext= useContext(GlobalContext);
    const {subscriptionPlans} = useContext(GlobalContext);

    const getstarted = () => {
        if (!gContext.isLoggedIn) {
            navigate('/app/login');
        } else {
            navigate('/app/dashboard');
        }
    }

    useEffect(() => {
        // AuthServices.paymentIntegration().then(res => {
        //     console.log("paymentintegration",res);
        //     setSubscriptionPlans(res);
        // }).catch(err => {
        //     console.log(err);
        // });
        console.log("cccd",subscriptionPlans);

    },[]);

    return (
            <div className=" bg-gray-300 h-full p-6 rounded-lg border-2 border-blue-500 flex flex-col relative overflow-hidden">
                <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    {/* <Intl id="trail"></Intl> */}
                    {subscriptionPlans?.results[0]?.name}
                </h2>
                <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b-2 border-gray-900 leading-none">
                    <span>{subscriptionPlans?.results[0]?.amount}</span>
                    <span className="text-lg ml-1 font-normal text-gray-900">
                        {/* <Intl id="/mo"></Intl> */}/
                        {subscriptionPlans?.results[0]?.duration}
                    </span>
                </h1>
                {
                    subscriptionPlans?.results[0]?.feature_list?.map( feature => {
                        return (
                            <p className="flex items-center text-gray-900 mb-2" key={feature.id}>
                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2.5"
                                        className="w-3 h-3"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>
                                {/* <Intl id="trailfeaturefirst"></Intl> */}
                                {feature.name}
                            </p>
                        )
                    })
                }
                {/* <p className="flex items-center text-gray-900 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="trailfeatureSecond"></Intl>
                </p>
                <p className="flex items-center text-gray-900 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="trailfeaturethird"></Intl>
                </p>
                <p className="flex items-center text-gray-900 mb-6">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="trailfeaturefourth"></Intl>
                </p> */}

                <button
                    onClick={() => {
                        getstarted();
                    }}
                    className="flex items-center mt-auto text-gray-900 bg-cyan-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-cyan-800 rounded transition ease-out"
                >
                    <Intl id="getStarted"></Intl>
                    <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                    >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </button>
                <p className="text-xs text-gray-900 mt-3">
                    <Intl id="trailexplanation"></Intl>
                </p>
            </div>
    )           
}

export default Trial