import React, { useState } from 'react'
import Intl from '../../Intl'
import AppHeader from '../../Header/AppHeader'
import Sidebar from '../../Sidebar/Sidebar'
import ChangePassword from './ChangePassword'
import ChangePasswordSuccess from './ChangePasswordSuccess'


const ChangePasswordPage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);



    return (

        <>
            <div className="flex h-screen overflow-hidden">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area starts */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <AppHeader />

                    <section className="text-gray-300 body-font">
                        <div className="container mx-auto flex px-5 py-10  flex-col">

                            <div className="flex flex-wrap w-full mb-5">
                                <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-300"><Intl id="changedPassword" /></h1>
                                    <div className="h-1 w-20 bg-avermass-blue-500 rounded"></div>
                                </div>
                            </div>


                            <div className="lg:w-2/6 md:w-1/2 px-8 py-4 flex flex-col">
                                {   !isPasswordUpdated ?
                                        <ChangePassword setIsPasswordUpdated={setIsPasswordUpdated} />
                                    :
                                        <ChangePasswordSuccess />
                                }

                                

                            </div>




                        </div>
                    </section>


                </div>
                {/* Content area ends */}

            </div>


        </>
    )
}

export default ChangePasswordPage
