import React from 'react'
import HeaderTop from '../../Header/HeaderTop'
import Header from '../../Header/Header'
import VerifyEmail from './VerifyEmail'
import Footer from '../../Footer/Footer'

const VerifyEmailpage = ({ location }) => {
	return (
		<>
			<HeaderTop />
			<Header />
			<VerifyEmail location={location} />
			<Footer />
		</>
	)
}

export default VerifyEmailpage
