import React from 'react';
import MyAccordion from './MyAccordion';
//import { questions } from './api';
import useTranslate from '../../../hooks/useTranslate';

const Accordion = () => {
  // const[data ,setData] = useState(questions);
  const [translate] = useTranslate();
  const questions = translate('questions', { isArray: true });
  return (
    <div className='w-full flex  text-xl'>
      <section className='w-full'>
        {questions?.map((curElem) => {
          const { id } = curElem;
          return <MyAccordion key={id} {...curElem} />;
        })}
      </section>
    </div>
  );
};

export default Accordion;
