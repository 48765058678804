import React, { useContext } from 'react';
import axios from 'axios';
import GlobalContext from '../../../../context/GlobalContext'
import GooglePayButton from '@google-pay/button-react';

function GooglePay(props) {
  const { close,amount,currency } = props;
  const {userProfile} = useContext(GlobalContext);
  const d = new Date();
  let ISO_Date = d.toISOString();
  let int = amount+".00"
  const token = localStorage.getItem('access_token');
  const params ={
    "id": "9EA5808828617250V", 
    "intent": "CAPTURE",       
    "status": "COMPLETED",      
    //"payment_gateway": "google",
    "purchase_units": [
        {
            "reference_id": "default", 
            "amount": {
                "currency_code": currency ? currency.toString() : "",
                "value": amount ? amount.toString() : ""
            },
            "payee": {
                "email_address": userProfile.email ? userProfile.email : "", 
                "merchant_id": "YQZCHTGHUK5P8" 
            },
            //"soft_descriptor": "GOOGLE *PYPLTEST", 
            "shipping": {
                "name": {
                    "full_name": userProfile.first_name || userProfile.last_name ? (userProfile.first_name ? userProfile.first_name : "") + " " + (userProfile.last_name ? userProfile.last_name : "") : "" // user info
                },
                "address": {
                    "address_line_1": "",
                    "address_line_2": "",
                    "admin_area_2": userProfile.city ? userProfile.city : "",
                    "admin_area_1": userProfile.state ? userProfile.state : "",
                    "postal_code": userProfile.pincode ? userProfile.pincode : "" , 
                    "country_code": userProfile.country.country_code ? userProfile.country.country_code : ""
                }
            },
            "payments": {
                "captures": [
                    {
                        "id": "31K9272559040534R", 
                        "status": "COMPLETED",  
                        "amount": {
                          "currency_code": currency ? currency.toString() : "",
                          "value": amount ? amount.toString() : ""
                      },
                        "final_capture": true,
                        "seller_protection": {
                            "status": "ELIGIBLE",
                            "dispute_categories": [
                                "ITEM_NOT_RECEIVED",
                                "UNAUTHORIZED_TRANSACTION"
                            ]
                        },
                        "create_time": ISO_Date,
                        "update_time": ISO_Date
                    }
                ]
            }
        }
    ],
    "payer": {
        "name": {
            "given_name": userProfile.first_name ? userProfile.first_name : "",
            "surname": userProfile.last_name ? userProfile.last_name : ""
        },
        "email_address": userProfile.email ? userProfile.email : "",
        "payer_id": "QRH7UY6VXYC7Q",
        "phone": {
            "phone_number": {
                "national_number": ""
            }
        },
        "address": {
          "address_line_1": "",
          "address_line_2": "",
          "admin_area_2": userProfile.city ? userProfile.city : "",
          "admin_area_1": userProfile.state ? userProfile.state : "",
          "postal_code": userProfile.pincode ? userProfile.pincode : "" , 
          "country_code": userProfile.country.country_code ? userProfile.country.country_code : ""
      }
    },
    "create_time": ISO_Date,
    "update_time": ISO_Date,
    /* "links": [
      {
          "href": "https://api.sandbox.paypal.com/v2/checkout/orders/9EA5808828617250V",
          "rel": "self",
          "method": "GET"
      }
  ] */
    
  }

  const headers = {
    'Authorization': "Basic c3Jpa2FudGg0OnNyaWthbnRo",
    'Content-Type': 'application/json'
  }
  

  const paymentTransaction = ()=> {
    
    debugger
    axios.post('https://forgery-detection-backend-xhmiwfoy2a-uc.a.run.app/api/v1/subscriptions/payment_transaction', params,{headers})
      .then(function (response) {
        console.log(response)
        if(response.status == 201){
          alert("payment successful and account updated")
        }else{
          alert("payment unsuccessful, something went wrong")
          
        }
        close();
      })
      .catch(function (error) {
        
        console.log(error);
        close();
      });
  }

  return (
    <>
      <GooglePayButton
        buttonSizeMode='fill'
        style={{ width: 330, height: 50, marginBottom: 10 }}
        environment='TEST'
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['MASTERCARD', 'VISA']
              },
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                  gateway: 'example',
                  gatewayMerchantId: 'exampleGatewayMerchantId'
                }
              }
            }
          ],
          merchantInfo: {
            merchantId: 'BCR2DN4TWCBJHS3J',
            merchantName: 'VERMA GmbH'
          },
          transactionInfo: {
            displayItems: [
              {
                label: 'Subtotal',
                type: 'SUBTOTAL',
                price: int
              },
              {
                label: 'Tax',
                type: 'TAX',
                price: '0.05'
              }
            ],
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: amount+".05",
            currencyCode: 'USD',
            countryCode: 'US'
          },
          callbackIntents: ['PAYMENT_AUTHORIZATION']
        }}
        onP
        onLoadPaymentData={(paymentRequest) => {
          console.log('Payment Success', paymentRequest);
          
          paymentTransaction();
          //Need to make API call for success
        }}
        onPaymentAuthorized={(paymentData) => {
          console.log('Payment Authorized', paymentData);
          return { transactionState: 'SUCCESS' };
        }}
      />
    </>
  );
}

export default GooglePay;
