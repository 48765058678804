import React, {useState} from 'react'
import { Link } from 'gatsby'
import AppHeader from '../../Header/AppHeader'
import Sidebar from '../../Sidebar/Sidebar'
import Intl from '../../Intl'
import FileUploader from './FileUploader'

const ForgeryDetection = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);    
    return (
        // <>
        //     <AppHeader />

        //     <section className="text-gray-600 body-font">
        //         <div className="container mx-auto flex px-5 py-10 items-center justify-center flex-col">
        //             <div className="text-center lg:w-2/3 w-full">
        //                 <div className="flex items-center justify-between">
        //                     <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Face Forgery Detection</h1>
        //                     <Link to="/app/face-forgery-detection/list/" className="pl-4 pr-8 py-2 text-white bg-blue-500 rounded shadow-xl"> View Scan Result List</Link>
        //                 </div>
        //                 <p className="mb-8 leading-relaxed">Meggings kinfolk echo park stumptown DIY, kale chips beard jianbing tousled. Chambray dreamcatcher trust fund, kitsch vice godard disrupt ramps hexagon mustache umami snackwave tilde chillwave ugh. Pour-over meditation PBR&amp;B pickled ennui celiac mlkshk freegan photo booth af fingerstache pitchfork.</p>
        //                 <div className="flex justify-center">
        //                 </div>
        //                 <FileUploader />
        //             </div>
        //         </div>
        //     </section>            
        //     <Footer />         
        // </>

        <>
            <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area starts */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <AppHeader />

                        <section className="text-gray-600 body-font">
                            <div className="container mx-auto flex px-5 py-10 items-center justify-center flex-col">
                                <div className="text-center lg:w-2/3 w-full">
                                    <div className="flex items-center justify-between">
                                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-300"><Intl id="SignatureForgeryDetection" /></h1>
                                        <Link to="/app/signature-forgery-detection/list/" className="pl-4 pr-8 py-2 text-white bg-blue-500 rounded shadow-xl hover:bg-blue-900"> <Intl id="viewScanResultList" /></Link>
                                    </div>
                                    <p className="mb-8 leading-relaxed text-gray-400"><Intl id="signatureforgeryDetectionResultExplanation" /></p>
                                    <div className="flex justify-center">
                                    </div>
                                    <FileUploader />
                                </div>
                            </div>
                        </section>  


                </div>
                {/* Content area ends */}

            </div>


        </>        
    )
}

export default ForgeryDetection
