import React, { useState } from 'react';
import Intl from '../../Intl';
import FacebookChatButton from '../SocialChatButton/FacebookChat';
import WhatsAppChatButton from '../SocialChatButton/WhatsappChat';

import AuthServices from '../../../services/auth/AuthServices';

import useTranslate from '../../../hooks/useTranslate';

import Modal from '../Modal/Modal';


function FeedbackForm() {

  const [translate] = useTranslate();

  // Modal status
  const [isOpen, SetIsOpen] = useState(false)

  // Close Modal function
  const closeModal = () => SetIsOpen(false)

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumer] = useState('');
  const [queryType, setQueryType] = useState('');
  const [message, setMessage] = useState('');

  const onInputChange = (name, value) => {
    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'mobileNumber') {
      setMobileNumer(value);
    } else if (name === 'queryType') {
      setQueryType(value);
    } else if (name === 'message') {
      setMessage(value);
    }
  };

  //const [record, setRecord] = useState({});
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (name && email && mobileNumber && queryType && message) {
      AuthServices.sendFeedback({
        name,
        email,
        phone_number: mobileNumber,
        query_type: queryType,
        message
      }).then((response) => {
        console.log(response);
      });
    }
  };

  return (
    <div
      className='lg:w-1/2 md:w-1/2 bg-gray-300 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 border-2 border-white hover:border-blue-accent-700'
      id='enquiry'
    >
      <h2 className='text-gray-900 text-3xl mb-1 font-sans title-font flex justify-center'>
        <Intl id="getintouchwithus"></Intl>
      </h2>
      <form className='mt-6' action='#' method='POST' onSubmit={onFormSubmit}>
        <div className='relative mb-4 m-3'>
          <label for='name' className='leading-7 text-sm ml-4 text-gray-900'>
            Name
          </label>
          <input
            type='text'
            id='name'
            name='name'
            required
            className='w-full bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out '
            autofocus
            autocomplete
            value={name}
            onChange={(e) => onInputChange('name', e.target.value)}
          />
        </div>
        <div className='relative mb-4 m-3'>
          <label for='email' className='leading-7 text-sm ml-4 text-gray-900'>
            Email
          </label>
          <input
            type='email'
            id='email'
            name='email'
            required
            className='w-full bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
            autofocus
            autocomplete
            value={email}
            onChange={(e) => onInputChange('email', e.target.value)}
          />
        </div>
        <div className='relative mb-4 m-3'>
          <label for='contact' className='leading-7 text-sm ml-4 text-gray-900'>
            <Intl id="contactno"></Intl>
          </label>

          <input
            type='tel'
            id='contact'
            name='contact'
            required
            className='w-full bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
            autofocus
            autocomplete
            value={mobileNumber}
            onChange={(e) => onInputChange('mobileNumber', e.target.value)}
          />
        </div>
        <div className='relative mb-4 m-3'>
          <label for='query' className='leading-7 text-sm ml-4 text-gray-900'>
            <Intl id="typeofquery"></Intl>
          </label>
          <select
            type='option'
            id='query'
            name='query'
            select
            className='w-full h-10 bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
            autofocus
            autocomplete
            required
            value={queryType}
            onChange={(e) => onInputChange('queryType', e.target.value)}
          >
            <option value={''}>{translate('chooseYourTopic')}</option>
            <option value={'TECHNOLOGY_RELATED'}> {translate('technologyRelated')} </option>
            <option value={'SECURITY_RELATED'}> {translate('securityRelated')} </option>
            <option value={'PRICING_RELATED'}> {translate('pricingRelated')} </option>
            <option value={'SUPPORT_ENQUIRIES'}> {translate('supportEnquiries')} </option>
            <option value={'SPECIAL_PACKAGE'}> {translate('specialPackage')} </option>
          </select>
        </div>
        <div className='relative mb-4 m-3'>
          <label for='message' className='leading-7 text-sm ml-4 text-gray-900'>
            <Intl id="message"></Intl>
          </label>
          <textarea
            id='message'
            name='message'
            className='w-full bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out'
            autofocus
            autocomplete
            required
            value={message}
            onChange={(e) => onInputChange('message', e.target.value)}
          ></textarea>
        </div>
        <button

          onClick={() => { SetIsOpen(true) }}
          type='submit'
          className='text-white m-3 bg-avermass-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-avermass-blue-700 text-lg'

        >
          <Intl id="submit"></Intl>

        </button>
        <Modal
          open={isOpen}
          close={closeModal}
          fade={true}
          title={ <Intl id='ThanksforfillingOutOurform' />}
          backdrop={true}
          fullscreen={"true"}
          centered={false}
          scrollable={true}
          transition={true}
          cancel={<Intl id="submit"></Intl>}
        >
          <h1 className='text-red-300'> 

          <Intl id='ThanksforfillingOutOurformExp1' /> <br /><br />

          <Intl id='ThanksforfillingOutOurformExp2' />
          </h1>
        </Modal>


        {/* Social Media Button */}
        {/* <div className='flex  space-x-4 text-center m-2'>
          <WhatsAppChatButton />
          <FacebookChatButton />
        </div> */}

        <p className="text-sm m-3 text-gray-900 mt-3 p-2">By signing up, you are agreeing to our terms and acknowledge reading our privacy notice.</p>
      </form>
    </div>
  );
}

export default FeedbackForm;
