import React from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {

    let classes = "font-semibold border-2 border-gray-400 px-4 py-3 mt-6 ";
    // if(props.className) classes += props.className;

    if(props.buttonStyle === "custom") 
        classes += props.className
    else
        classes += "bg-royal-blue-500 hover:bg-royal-blue-600 focus:bg-royal-blue-600 text-white "
        if(props.className) classes += props.className;


    return (
        <button type={props.type} className={classes}>
            {props.children}
        </button>
    )
}

Button.displayName = "Button";
Button.propTypes = {
    buttonStyle: PropTypes.oneOf(["default", "custom"]),
    type: PropTypes.oneOf(["button", "submit"]),
    loading: PropTypes.bool
};
Button.defaultProps = {
    buttonStyle: "default",
    type: "submit",
    loading: false
};

export default Button
