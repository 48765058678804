import React from 'react'
import { Link } from 'gatsby'
function Location() {
    return (
                <div className="lg:w-2/3 md:w-1/2 bg-gray-300 hover:border-blue-accent-700  overflow-hidden sm:mr-10  flex items-end justify-start relative border-2 border-white">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10685.138370217574!2d11.7789881!3d47.9695567!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dc1f15d5d39f8f0!2sVERMA%20GmbH%20(AVermass%20Consulting)!5e0!3m2!1sen!2sin!4v1647439702193!5m2!1sen!2sin" width="100%" height="100%" className="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no"></iframe>
                    <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                        <div className="lg:w-1/2 px-6">
                            <h2 className="title-font font-semibold text-white tracking-widest text-xs"> ADDRESS: </h2>
                            <p className="mt-1">VERMA GmbH <br /> Kirchgasse 6 <br />85653 Aying, Germany</p>
                        </div>
                        <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL: </h2>
                            <Link className="text-indigo-500 leading-relaxed"> support@avermass.de </Link>
                            
                        </div>
                    </div>
                </div>
    )
}

export default Location
