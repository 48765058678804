// import React, {useState, useEffect, useContext} from 'react'
// import PropTypes from 'prop-types'
import React, {useState,  useContext} from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'

import Intl from '../../Intl'

import InputText from '../../Core/InputText'
import Button from '../../Core/Button'
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import GlobalContext from '../../../context/GlobalContext'
import AuthServices from '../../../services/auth/AuthServices'

const ResetPassword = ({location}) => {

    const params = new URLSearchParams(location.search);
    const input = { "token": params.get("token") }

    const gContext = useContext(GlobalContext);
    if (gContext.isLoggedIn) navigate('/app/dashboard')

    
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState(input);
    const [recordError, setRecordError] = useState({});

    const onHandleChange = (name, value) => {
        setRecord({ ...record, [name]: value });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (isLoading) {
            return null;
        }
        setIsLoading(true);
        AuthServices.resetPassword(record).then((response) => {
            console.log(response)
            setRecord({});
            setRecordError({});
            navigate('/app/reset-password-success')
            setIsLoading(false);
            
        }).catch((error) => {
            // console.log(error.response.data)
            setRecordError(error.response.data);
            setIsLoading(false);
        });
    };

    return (

        <>
            <section className="text-gray-100 body-font bg-gray-100 py-4">
                <div className="container px-5 py-2 mx-auto flex flex-wrap items-center">
                    <div className="lg:w-2/6 md:w-1/2 mx-auto rounded-lg px-8 py-4 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-white border border-gray-300 shadow">

                        <h1 className="text-xl text-gray-100 md:text-2xl font-bold leading-tight"><Intl id="resetpassword" /> </h1>
                        <p className="py-2"> Create new credentials for your account </p>

                        <form className="mt-2" action="#" method="POST" onSubmit={onFormSubmit}>
                            {recordError.detail && (
                                <div
                                    className="mb-4 bg-red-200 rounded-b text-red-900 px-4 py-3 shadow-md"
                                    role="alert"
                                >
                                    <div className="flex">
                                        <div>
                                            <p className="text-sm">{recordError.detail || ""}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div>
                                <InputText type="password" labelText={<Intl id="password"/>} id="password" name="password" value={record.password || ""} placeholder="Enter Password" onChange={onHandleChange} error={ recordError.password && recordError.password || " "} />
                            </div>
                            <div>
                                <InputText type="password" labelText={<Intl id="confirmPassword"/>} id="confirm_password" name="confirm_password" value={record.confirm_password || ""} placeholder="Enter Confirm Password" onChange={onHandleChange} error={ recordError.confirm_password && recordError.confirm_password || ""} />
                            </div>


                            <Button type="submit" className="w-full"> Reset Password </Button>
                        </form>

                        <hr className="my-6 border-gray-300 w-full" />


                        <p className="mt-8"><Intl id="NeedAnAcoount"></Intl><Link to="/app/signup" className="text-blue-500 hover:text-blue-700 font-semibold"><Intl id="createAnAccount"></Intl></Link></p>

                    </div>
                </div>
            </section>

        </>
    )

}

ResetPassword.propTypes = {

}

export default ResetPassword
