import React, { useState } from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'gatsby'

import Intl from '../../Intl'

import InputText from '../../Core/InputText'
import Button from '../../Core/Button'
import AuthServices from '../../../services/auth/AuthServices'


const ChangePassword = props => {

    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState({});
    const [recordError, setRecordError] = useState({});

    const onHandleChange = (name, value) => {
        setRecord({ ...record, [name]: value });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (isLoading) {
            return null;
        }
        setIsLoading(true);
        AuthServices.changePassword(record).then((response) => {
            console.log(response)
            setRecord({});
            setRecordError({});
            props.setIsPasswordUpdated(true)
            setIsLoading(false);
            
        }).catch((error) => {
            // console.log(error.response.data)
            setRecordError(error.response.data);
            setIsLoading(false);
        });
    };    
    return (
        <>
            <form className="mt-2" action="#" method="POST" onSubmit={onFormSubmit}>
                {recordError.detail && (
                    <div
                        className="mb-4 bg-red-200 rounded-b text-red-900 px-4 py-3 shadow-md"
                        role="alert"
                    >
                        <div className="flex">
                            <div>
                                <p className="text-sm">{recordError.detail || ""}</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="py-1">
                    <InputText  type="password" labelText={<Intl id="oldpassword" />} id="old_password" name="old_password" value={record.old_password || ""} placeholder="Enter Old Password" onChange={onHandleChange} error={recordError.old_password && recordError.old_password || ""} />
                </div>

                <div className="py-1">
                    <InputText type="password" labelText={<Intl id="newpassword" />} id="new_password" name="new_password" value={record.new_password || ""} placeholder="Enter New Password" onChange={onHandleChange} error={recordError.new_password && recordError.new_password || ""} />
                </div>

                <div className="py-1">
                    <InputText type="password" labelText={<Intl id="confirmpassword" />} id="confirm_password" name="confirm_password" value={record.confirm_password || ""} placeholder="Enter New Password" onChange={onHandleChange} error={recordError.confirm_password && recordError.confirm_password || ""} />
                </div>


                <Button type="submit" className="w-full"> <Intl id="changedPassword" /> </Button>
            </form>

        </>
    )
}

ChangePassword.propTypes = {

}

export default ChangePassword
