import React from 'react';

function Spinner() {
  return (
    <div
      class='spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600'
      role='status'
    >
      <span class='visually-hidden'>Loading...</span>
    </div>
  );
}

export default Spinner;
