import React from 'react'
import { Router } from '@reach/router'
// import { Router, Route } from '@reach/router'
// import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import PrivateRoute from '../components/App/PrivateRoute'
import LoginPage from '../components/App/Login'
import SignupPage from '../components/App/Signup'
import ForgotPasswordPage from '../components/App/ForgotPassword'
import ForgotPasswordAcknowledgementPage from '../components/App/ForgotPassword/ForgotPasswordAcknowledgementPage'
import ResetPasswordPage from '../components/App/ResetPassword'
import ResetPasswordAcknowledgementPage from '../components/App/ResetPassword/ResetPasswordAcknowledgementPage'

import Dashboard from '../components/App/Dashboard'
import ChangePasswordPage from '../components/App/ChangePassword'

import ForgeryDetection from '../components/App/ForgeryDetection'
import ForgeryDetectionList from '../components/App/ForgeryDetection/ForgeryDetectionList'

import FaceForgeryDetection from '../components/App/FaceForgeryDetection'
import FaceForgeryDetectionList from '../components/App/FaceForgeryDetection/ForgeryDetectionList'

import SignatureForgeryDetection from '../components/App/SignatureForgeryDetection'
import SignatureForgeryDetectionList from '../components/App/SignatureForgeryDetection/ForgeryDetectionList'

import scrummaster from "../components/App/ScrumMaster/index"




import VerifyEmailPage from '../components/App/VerifyEmail'

import DeactivateAccountPage from '../components/App/DeactivateAccount'

import AboutUsPage from '../components/AboutUs/AboutUs' 

import ContactUsPage from '../components/ContactUs/ContactUs' 

import PricingPage from '../components/Pricing/Pricing' 

import ProductPage from '../components/Product/ProductPage'

import BlogPage from '../components/Blog/Blog'








const App = ({location}) => {
    // const redirect = location.pathname.split('/').pop();
    return (
        <>
         

            <Router basepath="/app">
                {/* Dashboard routes */}
                <PrivateRoute path="/dashboard" component={Dashboard} />

                {/* forgery detection routes */}
                <PrivateRoute path="/forgery-detection" component={ForgeryDetection} />
                <PrivateRoute path="/forgery-detection/list" component={ForgeryDetectionList} />

                {/* face forgery detection routes */}
                <PrivateRoute path="/face-forgery-detection" component={FaceForgeryDetection} />
                <PrivateRoute path="/face-forgery-detection/list" component={FaceForgeryDetectionList} />

                {/* signature forgery detection routes */}
                <PrivateRoute path="/signature-forgery-detection" component={SignatureForgeryDetection} />
                <PrivateRoute path="/signature-forgery-detection/list" component={SignatureForgeryDetectionList} />

                {/* scrum Master routes */}
                <PrivateRoute path="/scrummaster" component={scrummaster} />


                <PrivateRoute path="/change-password" component={ChangePasswordPage} />


                <AboutUsPage path="/aboutUs" />
                            
                <ContactUsPage path="/ContactUs" />


                <PricingPage path="/pricing" />

                
                {/* service page */}
                <ProductPage path="/product" />


                {/* Blog Page */}
                <BlogPage path="/blog" />
                

                

                {/* Auth routes */}
                <LoginPage path="/login" />
                <SignupPage path="/signup" />
                <ForgotPasswordPage path="/forgot-password" />
                <ForgotPasswordAcknowledgementPage path="/forgot-password-success" />

                <ResetPasswordPage path="/reset-password" />
                <ResetPasswordAcknowledgementPage path="/reset-password-success" />

                <DeactivateAccountPage path="/deactivate" />

                {/* Email verification route */}
                <VerifyEmailPage path="/verify-email"  />

            </Router>            
        </>
    )
}

export default App
