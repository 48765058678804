import React from 'react'
import HeaderTop from '../../Header/HeaderTop'
import Header from '../../Header/Header'
import ForgotPassword from './ForgotPassword'
import Footer from '../../Footer/Footer'

const ForgotPasswordPage = () => {
	return (
		<>
			<HeaderTop />
			<Header />
			<ForgotPassword />
			<Footer />
		</>
	)
}

export default ForgotPasswordPage
