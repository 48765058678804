import React,{useState} from 'react';


const MyAccordion = ({question, answer}) => {
    const[show ,setShow] = useState(false);
    return (
        <>
            <div className='bg-transparent text-white mt-6 w-full font-sans'>
                <div className='flex'>
                    <div className='font-6 hover:text-blue-700 active:bg-red-600'>
                        <p onClick={()=>setShow(!show)} className=' w-6 text-5xl h-8 flex-row'> {show? "-" :"+" } </p>
                    </div>
                    <div className='ml-10 p-4 font-sans'>
                        <h3 className='font-sans'>{question}</h3>
                    </div>
                </div>
                    
                
                {/* <h3 className='font-sans'>{question}</h3>
                <p onClick={()=>setShow(!show)} className='border-2 border-green text-3xl w-6 h-8 rounded-lg items-center text-center flex justify-center'> {show? "-" :"+" } </p> */}
            
        
                {
                    show && <p className='w-3/4 border-t-2 border-blue-800 text-white p-5'>" {answer} "</p>
                }
            </div>
            
        </>
    )
}

export default MyAccordion;
