import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Img from '../../assets/images/desktop01.jpg'

const Blog = () => {
    return (
        <div>
            <Header />
            <h1 className="p-6 text-white text-2xl flex items-center justify-center">
                Blog Page Body 
            </h1>
            <div>
            <img src={Img} alt="image" />
            </div>
            
            <Footer />
        </div>
    )
}
export default Blog;