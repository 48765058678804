import React, { useEffect,useContext,useState } from 'react';
import axios from 'axios';

import Spinner from '../../Spinner';
import GlobalContext from '../../../../context/GlobalContext'

const RazorPayButtonWrapper = ({ currency, showSpinner, amount, style, onPopupClose }) => {


  const {userProfile} = useContext(GlobalContext);

  const token = localStorage.getItem('access_token');
  const[orderDetails , setOrderDetails] = useState({});
  const[loading , setLoading] = useState(false);
 
  

  
  /* const paymentTransaction = ()=> {
    
    axios.post('https://forgery-detection-backend-xhmiwfoy2a-uc.a.run.app/api/v1/subscriptions/payment_transaction', params,{headers})
      .then(function (response) {
        
        if(response.status == 201){
          alert("payment successful and account updated")
        }else{
          alert("payment unsuccessful, something went wrong")
          
        }
        onPopupClose();
      })
      .catch(function (error) {
        console.log(error);
        onPopupClose();
      });
  } */

  const createOrder = ()=> {
    setLoading(true)
    const params = {
      "amount": Number(amount ),
      "currency": currency
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    
    axios.post('https://forgery-detection-backend-xhmiwfoy2a-uc.a.run.app/api/v1/subscriptions/create-order-razorpay/', params,{headers})
      .then(function (response) {
        
        if(response.status == 200){
          setOrderDetails(response.data.result)
          displayRazorpay(response.data.result.id)
          setLoading(false)
          
        }
        onPopupClose();
      })
      .catch(function (error) {
        console.log(error);
        onPopupClose();
        setLoading(false)
      });
  }
  
  const loadScript = (src) => {

    return new Promise ((resolve)=>{
      const script = document.createElement('script')
      script.src = src
      
      script.onload = () =>{
        resolve(true)
      }

      script.onerror = () =>{
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  const  displayRazorpay =  async (orderID) => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if(!res){
      alert("You Are Offline")
      return
    }

    const options =  {
      "key": "rzp_test_P1rAdN863L865u",  // key_id
      "name": userProfile.first_name || userProfile.last_name ? (userProfile.first_name ? userProfile.first_name : "") + " " + (userProfile.last_name ? userProfile.last_name : "") : "",
      "description": "Test Transaction",
      "order_id": orderID, 
      
      "prefill": {
          "name": userProfile.first_name || userProfile.last_name ? (userProfile.first_name ? userProfile.first_name : "") + " " + (userProfile.last_name ? userProfile.last_name : "") : "",
          "email": userProfile.email ? userProfile.email : "",
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#3399cc"
      },

      handler: function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature)
      },

    }

    
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()

    
  }

 
  return (
    <>
    {
      loading ? <Spinner/> : null
    }
      
     {
      orderDetails ? <> 
      
      <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" onClick={()=>{ 
        
        if(amount != undefined){

          createOrder()
        }

        
        
        }} >
          RazorPay
      </button>
      
      
      </> : null
     }
      
    </>
  );
};

function Razorpay(props) {
  let int = ''
  const { close,amount,currency } = props;
  
  return (
    <div style={{ maxWidth: '750px', minHeight: '200px' }}>
     
        <RazorPayButtonWrapper
          currency={currency}
          showSpinner={false}
          amount={amount}
          style={{ layout: 'horizontal', label: 'pay', tagline: true }}
          onPopupClose={close}
        />
      
    </div>
  );
}

export default Razorpay;
