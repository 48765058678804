import React from 'react'
import HeaderTop from '../../Header/HeaderTop'
import Header from '../../Header/Header'
import Signup from './Signup'
import Footer from '../../Footer/Footer'

const SignupPage = () => {
	return (
		<>
			<HeaderTop />
			<Header />
			<Signup />
			<Footer />
		</>
	)
}

export default SignupPage
