import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import HeaderTop from '../Header/HeaderTop';
import Intl from '../Intl';
import { Link } from 'gatsby';

// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import ReactWhatsapp from 'react-whatsapp'

import heroImage from '../../assets/images/desktop05.jpg';
import SupportUs from '../../assets/images/support.png';
import QueryUs from '../../assets/images/query.png';

import Location from '../Core/Location/Location';
import FeedbackForm from '../Core/Feedback/FeedbackForm';

const ContactUsPage = () => {
  return (
    <>
      <HeaderTop />
      <Header />

      <section>
        <div className='container px-2 py-2 mx-auto flex-row'>
          <div
            className='container mx-auto flex px-2 py-32 md:flex-row flex-col items-center bg-no-repeat bg-center  bg-cover bg-opacity-40'
            style={{ backgroundImage: `url(${heroImage})` }}
          >
            <div className='p-8 lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center'>
              <h1 className='title-font  mb-4 font-serif text-6xl text-gray-900 '>
                <Intl id="we'd"></Intl> <span className='text-blue-300 '> <Intl id="lovetohearfromyou"></Intl> </span>
              </h1>
              <h2 className='mb-6 text-tuatara-100 text-2xl font-sans'>
                Share your information with us,
                <span className='text-blue-300 font-sans text-2xl'> <Intl id="needademo"></Intl> </span>
                and our team will contact you to assist with all of your needs.
              </h2>
            </div>
          </div>

          <section>
            <div className='container py-12  mx-auto flex-row'>
              <div className='flex sm:flex-nowrap flex-wrap z-0'>
                {/* Location & Address */}
                <Location />

                {/* FeedBack Form */}
                <FeedbackForm />
              </div>
            </div>
          </section>
        </div>

        {/* PartnerEnquiries & SupportEnquiries */}
        <section className='bg-gray-700 text-white container mt-6 mx-auto flex-row'>
          <div className='container flex  md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col'>
            <div className='flex flex-col flex-wrap lg:py-0  lg:m-10  lg:w-1/2  lg:text-left text-center'>
              <div className=' p-8 shadow-xl  text-gray-100 border-t-2 border-b-2 border-gray-600'>
                <div className='flex text-avermass-blue-500 '>
                  <svg
                    style={{ backgroundImage: `url(${QueryUs})` }}
                    fill='currentColor'
                    className='w-14 bg-no-repeat bg-center bg-cover'
                    viewBox='0 0 16 16'
                  ></svg>
                </div>
                <h4 className='py-4 text-2xl font-semibold text-gray-200'>
                  <Link to='\'><Intl id="specialoffers"></Intl></Link>
                </h4>
                <p className='font-sans'>
                  <Intl id="specialoffersExplanationfirst"></Intl> <br />
                  <Intl id="specialoffersExplanationsecond"></Intl>
                </p>
                <Link
                  className='mt-3 text-indigo-800 inline-flex items-center'
                  to='#enquiry'
                  //onClick={() => enqFunc('partnerEnquiries')}
                >
                  <Intl id="findspecialoffers"></Intl>
                  <svg
                    fill='none'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    className='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                  >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>
                </Link>
              </div>
            </div>

            <div className='flex flex-col flex-wrap lg:py-0  lg:m-10  lg:w-1/2  lg:text-left text-center'>
              <div className=' p-8 shadow-xl  text-gray-100 border-t-2 border-b-2 border-gray-600'>
                <div className='flex text-avermass-blue-500 '>
                  <svg
                    style={{ backgroundImage: `url(${SupportUs})` }}
                    fill='currentColor'
                    className='w-14 bg-no-repeat bg-center bg-cover'
                    viewBox='0 0 16 16'
                  ></svg>
                </div>
                <h4 className='py-4 text-2xl font-sans text-gray-200'>
                  <Link to='\'><Intl id="supportenquiries"></Intl></Link>
                </h4>
                <p className='font-lg'>
                  <Intl id="upportenquiriesexplanationfirst"></Intl> <br />
                  <Intl id="supportenquiriesexplanationsecond"></Intl>
                </p>
                <Link
                  className='mt-3 text-indigo-800 inline-flex items-center'
                  to='#enquiry'
                  //onClick={() => enqFunc('supportEnquiries')}
                >
                  <Intl id="getsupport"></Intl>
                  <svg
                    fill='none'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    className='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                  >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default ContactUsPage;
