import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import GlobalContext from '../../../../context/GlobalContext';
import { navigate } from '@reach/router';
import Intl from '../../../Intl';




function ScrumMasterPricing() {

    const [displayOption, setDisplayOption] = useState("trail");


    const gContext = useContext(GlobalContext);
    const { subscriptionPlans } = useContext(GlobalContext);

    const getstarted = () => {
        if (!gContext.isLoggedIn) {
            navigate('/app/login');
        } else {
            navigate('/app/dashboard');
        }
    }

    useEffect(() => {
        // AuthServices.paymentIntegration().then(res => {
        //     console.log("paymentintegration",res);
        //     setSubscriptionPlans(res);
        // }).catch(err => {
        //     console.log(err);
        // });
        console.log("cccd", subscriptionPlans);

    }, []);

    const handleChange = (e) => {
        setDisplayOption(e.target.value);
        // console.log("  HII  "+e.target.value)
    }


    return (
        <div>
            <div className='flex justify-between items-center p-6'>

                <div className='p-4'>
                    <select
                        type='option'
                        id='services'
                        name='services'
                        select
                        className='w-full h-12 bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-10'
                        autofocus
                        autocomplete
                        required
                        onChange={handleChange}

                    >
                        <option> Choose your pricing plan </option>
                        <option id='trail' name="trail" value={"trail"} > Customized plans for all sized companies </option>
                        <option id='monthly' name="monthly" value={"monthly"}>Per license billing</option>
                        <option id='yearly' name="yearly" value={"yearly"}>Per team billing</option>
                        <option id='trail' name="trail" value={"trail"} > Monthly billing plan </option>
                        <option id='monthly' name="monthly" value={"monthly"}>Annual plan with concessions</option>
                        <option id='yearly' name="yearly" value={"yearly"}>30 day trial period</option>
                        <option id='trail' name="trail" value={"trail"} > Initial launch offer </option>

                    </select>
                </div>
                <div className='p-4 '>
                    <select
                        type='option'
                        id='services'
                        name='services'
                        select
                        className='w-full h-12 bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-10'
                        autofocus
                        autocomplete
                        required
                        onChange={handleChange}
                    >
                        <option>  Choose your company size   </option>
                        <option id='trail' name="trail" value={"trail"} > Small sized companies</option>
                        <option id='monthly' name="monthly" value={"monthly"}>Medium sized companies</option>
                        <option id='yearly' name="yearly" value={"yearly"}>Large companies</option>
                        <option id='trail' name="trail" value={"trail"} > Extra Large companies </option>

                    </select>
                </div>

            </div>




            <div className=" bg-gray-300 h-full p-6 rounded-lg border-2 border-blue-500 flex flex-col relative overflow-hidden">

                {
                    displayOption === "trail" &&
                    <div>
                        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                            Trial
                        </h2>
                        <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b-2 border-gray-900 leading-none">
                            0 $
                            {/* <span>{subscriptionPlans?.results[0]?.amount}</span> */}
                            <span className="text-lg ml-1 font-normal text-gray-900">
                                / week

                            </span>
                        </h1>

                    </div>
                }{

                    displayOption === "monthly" &&

                    <div>
                        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                            Monthly user
                            {/* <Intl id="trail"></Intl> */}
                            {/* {subscriptionPlans?.results[0]?.name} */}
                        </h2>
                        <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b-2 border-gray-900 leading-none">
                            40 $
                            {/* <span>{subscriptionPlans?.results[0]?.amount}</span> */}
                            <span className="text-lg ml-1 font-normal text-gray-900">
                                /Monthly

                                {/* <Intl id="/mo"></Intl> */}
                                {/* {subscriptionPlans?.results[0]?.duration} */}
                            </span>
                        </h1>

                    </div>
                }{
                    displayOption === "yearly" &&
                    <div>
                        <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                            Yearly
                            {/* <Intl id="trail"></Intl> */}
                            {/* {subscriptionPlans?.results[0]?.name} */}
                        </h2>
                        <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b-2 border-gray-900 leading-none">
                            200 $
                            {/* <span>{subscriptionPlans?.results[0]?.amount}</span> */}
                            <span className="text-lg ml-1 font-normal text-gray-900">
                                /Yearly

                                {/* <Intl id="/mo"></Intl> */}
                                {/* {subscriptionPlans?.results[0]?.duration} */}
                            </span>
                        </h1>

                    </div>
                }


                {
                    subscriptionPlans?.results[0]?.feature_list?.map(feature => {
                        return (
                            <p className="flex items-center text-gray-900 mb-2" key={feature.id}>
                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2.5"
                                        className="w-3 h-3"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>
                                {/* <Intl id="trailfeaturefirst"></Intl> */}
                                {feature.name}
                            </p>
                        )
                    })
                }
                {/* <p className="flex items-center text-gray-900 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="trailfeatureSecond"></Intl>
                </p>
                <p className="flex items-center text-gray-900 mb-2">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="trailfeaturethird"></Intl>
                </p>
                <p className="flex items-center text-gray-900 mb-6">
                    <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0">
                        <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2.5"
                            className="w-3 h-3"
                            viewBox="0 0 24 24"
                        >
                            <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                    </span>
                    <Intl id="trailfeaturefourth"></Intl>
                </p> */}

                <button
                    onClick={() => {
                        getstarted();
                    }}
                    className="flex items-center mt-auto text-gray-900 bg-cyan-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-cyan-800 rounded transition ease-out"
                >
                    <Intl id="getStarted"></Intl>
                    <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-4 h-4 ml-auto"
                        viewBox="0 0 24 24"
                    >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </button>
                <p className="text-xs text-gray-900 mt-3">
                    <Intl id="trailexplanation"></Intl>
                </p>
            </div>
        </div>
    )
}

export default ScrumMasterPricing