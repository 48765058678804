import React, {useState, useEffect} from 'react'
import { Link } from 'gatsby'
// import Header from '../../Header/Header'
// import Footer from '../../Footer/Footer'
import FaceForgeryDetectionService from '../../../services/faceForgeryDetection/FaceForgeryDetectionServices'
import Intl from '../../Intl'

import AppHeader from '../../Header/AppHeader'
import Sidebar from '../../Sidebar/Sidebar'

const ForgeryDetectionList = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);    

    const [isLoading, setIsLoading] = useState(true)
    const [recordList, setRecordList] = useState([])

    useEffect(() => {
        FaceForgeryDetectionService.getAllResult().then((response) => {
            setRecordList(response);
            setIsLoading(false)
            console.log(response)
        }).catch((error) => { setIsLoading(false) });
    }, []);

    return (
        // <>
        //     <Header />
        //     <div className="container mx-auto p-2">
        //         <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
        //             <div className="flex items-center justify-between">
        //                 <h2 className="px-5 py-5 text-3xl font-bold text-gray-700"> Recent Face Forgery Scan List </h2>
        //                 <Link to="/app/face-forgery-detection/" className="pl-4 pr-8 py-2 text-white bg-blue-500 rounded shadow-xl"> Scan New File</Link>
        //             </div>
        //             <table className="min-w-full leading-normal">
        //                 <thead>
        //                     <tr>
        //                         <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
        //                             Uploaded At
        //                         </th>
        //                         <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
        //                             File
        //                         </th>
        //                         <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
        //                             Score
        //                         </th>
        //                         <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
        //                             Result
        //                         </th>
        //                         <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
        //                             Result File
        //                         </th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>

        //                     { recordList && recordList.map((row, index) =>

        //                         <tr key={index}>
        //                             <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        //                                 <div className="flex items-center">
        //                                     <div className="ml-3">
        //                                         <p className="text-gray-900 whitespace-no-wrap">
        //                                             {row.created_at}
        //                                         </p>
        //                                     </div>
        //                                 </div>
        //                             </td>
        //                             <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        //                                 <p className="text-gray-900 whitespace-no-wrap">
        //                                     {row.slug}
        //                                 </p>
        //                             </td>
        //                             <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        //                                 <p className="text-gray-900 whitespace-no-wrap">
        //                                     {row.score}
        //                                 </p>
        //                             </td>
        //                             <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        //                                 { row.result == 'NOT_FORGED' && 
        //                                     <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
        //                                         {row.result}
        //                                     </span>
        //                                 }
        //                                 { row.result == 'FORGED' && 
        //                                     <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
        //                                         {row.result}
        //                                     </span>
        //                                 }
        //                                 { row.result == 'ERROR' && 
        //                                     <span className="relative inline-block px-3 py-1 font-semibold text-yellow-900 leading-tight">
        //                                         {row.result}
        //                                     </span>
        //                                 }
        //                             </td>
        //                             <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
        //                                 <a href={row.result_file} target="_blank"  className="text-gray-900 whitespace-no-wrap">
        //                                     view output
        //                                 </a>
        //                             </td>

        //                         </tr>

        //                     )}   

        //                 </tbody>
        //             </table>
        //         </div>
        //     </div>
        //     <Footer />
        // </>

        <>
            <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area starts */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <AppHeader />

                    <div className="container mx-auto p-2">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <div className="flex items-center justify-between">
                                <h2 className="px-5 py-5 text-3xl font-bold text-gray-300"> <Intl id="recentFaceForgeryScanList" /> </h2>
                                <Link to="/app/face-forgery-detection/" className="pl-4 pr-8 py-2 text-white bg-blue-500 rounded shadow-xl"> <Intl id="scanNewFile" /></Link>
                            </div>
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                            <Intl id="uploadAT" />
                                        </th>
                                        <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                            <Intl id="file" />
                                        </th>
                                        <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                            <Intl id="score" />
                                        </th>
                                        <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                            <Intl id="result" />
                                        </th>
                                        <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                                            <Intl id="resultFile" />
                                        </th>
                                    </tr>
                                </thead>
                            <tbody>

                            { recordList && recordList.map((row, index) =>

                                <tr key={index}>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        <div className="flex items-center">
                                            <div className="ml-3">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {row.created_at}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {row.slug}
                                        </p>
                                    </td>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {row.score}
                                        </p>
                                    </td>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        { row.result === 'NOT_FORGED' && 
                                            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                {row.result}
                                            </span>
                                        }
                                        { row.result === 'FORGED' && 
                                            <span className="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                                                {row.result}
                                            </span>
                                        }
                                        { row.result === 'ERROR' && 
                                            <span className="relative inline-block px-3 py-1 font-semibold text-yellow-900 leading-tight">
                                                {row.result}
                                            </span>
                                        }
                                    </td>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        <a href={row.result_file} target="_blank"  className="text-gray-900 whitespace-no-wrap">
                                            <Intl id="viewOutput" />
                                        </a>
                                    </td>

                                </tr>

                            )}   

                        </tbody>
                    </table>
                </div>
            </div>                        


                </div>
                {/* Content area ends */}

            </div>


        </>          
    )
}

export default ForgeryDetectionList
