import axios from 'services/core'
import { handleResponse, handleError } from '../response'; 

// Upload file
const uploadFile = async (record, config) => { 
    return await axios.post(`/api/v1/file-scanner/upload/`, record, config).then(handleResponse).catch(handleError); 
}; 

// Fetch Result
const getResult = async (slug) => { 
    return await axios.get(`/api/v1/file-scanner/result/${slug}/`).then(handleResponse).catch(handleError); 
}; 

// Get All Result
const getAllResult = async (slug) => { 
    return await axios.get(`/api/v1/file-scanner/result/`).then(handleResponse).catch(handleError); 
}; 

// Export all functions
const ForgeryDetectionServices = {
    uploadFile,
    getResult,
    getAllResult,
};
export default ForgeryDetectionServices;
