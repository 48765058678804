import React from "react";

const ScrumFAQ = () => {
    return (
        <div className="text-gray-900 text-base">
            1.What is the Agile Manifesto? 
            <br /> Ans: Four values and principles behind the Agile philosophy
            Individuals and interactions over processes and tools
            Working software over comprehensive documentation
            Customer collaboration over contract negotiation
            Responding to change over following a plan
            That is, while there is value in the items on the right, we value the items on the left more. <br /> <br />
            2.What is empiricism?
            <br /> Ans:Decisions are made based on observation, experimentation and experience rather than on detailed upfront planning. Simply, learning by doing. <br /> <br />
            3.What are the three pillars of Scrum and why are they important?
            <br /> Ans:The pillars are Transparency, Inspection and Adaptation
            These pillars form the basis of Scrum
            <br /> <br />
            4.What are the Five events in Scrum?
            <br /> Ans:The Sprint
            Sprint Planning
            The Daily Scrum
            Sprint Review
            Sprint Retrospective <br /> <br />
            5.What are the Three roles in Scrum?
            <br /> Ans:The Scrum Master
            The Product Owner
            Developers <br /> <br />
            6.What are the Artefacts in Scrum?
            <br /> Ans:The Product Backlog
            The Sprint Backlog
            The Increment <br /> <br />
            7.What events must the Scrum Master be present in?
            <br /> Ans:Sprint itself
            Sprint Planning
            Sprint Review
            Sprint Retrospective <br /> <br />
            8.What events must the Product Owner be present in?
            <br /> Ans:Sprint itself
            Sprint Planning
            Sprint Review
            Sprint Retrospective <br /> <br />
            9.What events must the Developers be present in?
            <br /> Ans:Sprint itself
            The Daily Scrum
            Sprint Planning
            Sprint Review
            Sprint Retrospective <br /> <br />
            10.What is the Product Goal?
            <br /> Ans:The Product Goal is the objective of the Sprint and usually results in releasable value <br /> <br />
            11.How long is a Sprint?
            Fixed length events of one month or less to create consistency. <br /> <br />
            12.What is the outcome of the Sprint?
            <br /> Ans:A releasable increment of value
            How does a Sprint control risk? 
            By limiting work, releasing value early and often and enabling frequent learning <br /> <br />
            13.What is the purpose of the Daily Scrum?
            <br /> Ans:Inspect progress toward the Sprint Goal and adapt the Sprint Backlog as necessary, adjusting the upcoming planned work. <br /> <br />
            14.What are the outcomes of the Daily Scrum?
            <br /> Ans:A plan for the day and a list of impediments to be resolved by either the team or the Scrum Master <br /> <br />
            15.What is the timebox for the Daily Scrum?
            <br /> Ans:15 minutes <br /> <br />
            16.What is the purpose of Sprint Planning?
            <br /> Ans:Sprint Planning initiates the Sprint by laying out the work to be performed for the Sprint. This resulting plan is created by the collaborative work of the entire Scrum Team. <br /> <br />
            17.What are the outcomes of Sprint Planning?
            <br /> Ans:The Sprint Goal
            An initial Sprint Backlog <br /> <br />
            18.What is the timebox for Sprint Planning?
            <br /> Ans:Sprint Planning is timeboxed to a maximum of eight hours for a one-month Sprint. For shorter Sprints, the event is usually shorter. <br /> <br />
            19.What is the purpose of the Sprint Review?
            <br /> Ans:The purpose of the Sprint Review is to inspect the outcome of the Sprint and determine future adaptations. The Scrum Team presents the results of their work to key stakeholders and progress toward the Product Goal is discussed. <br /> <br />
            20.Who attends the Sprint Review?
            <br /> Ans:The Scrum Team
            Invited Stakeholders <br /> <br />
            21.What is the timebox for the Sprint Review?
            <br /> Ans:Timeboxed to a maximum of four hours for a one-month Sprint. For shorter Sprints, the event is usually shorter. <br /> <br />
            22.What are the outcomes of the Sprint Review?
            During the event, the Scrum Team and stakeholders review what was accomplished in the Sprint and what has changed in their environment. Based on this information, attendees collaborate on what to do next. The Product Backlog may also be adjusted to meet new opportunities <br /> <br />
            23.What is the purpose of the Sprint Retrospective? 
            <br /> Ans:The purpose of the Sprint Retrospective is to plan ways for a team to increase its quality and effectiveness. The team does this by reviewing its past performance and generating concrete actions for improvement, to be executed in the next Sprint. <br /> <br />
            24.What is the timebox for the Sprint Retrospective?
            <br /> Ans:Timeboxed to a maximum of three hours for a one-month Sprint. For shorter Sprints, the event is usually shorter. <br /> <br />
            25.What are the outcomes of the Sprint Retrospective?
            <br /> Ans:Actions for improvement to be executed in subsequent Sprints. <br /> <br />
            26.Who must attend the Sprint Retrospective?
            <br /> Ans:The Scrum team <br /> <br />
            27.What are the five values of Scrum?
            <br /> Ans:Successful use of Scrum depends on people becoming more proficient in living five values:
            Commitment, Focus, Openness, Respect, and Courage <br /> <br />
            28.Who owns the Product Backlog?
            <br /> Ans:The Product Owner <br /> <br />
            29.Who owns the Sprint Backlog?
            <br /> Ans:The Developers <br /> <br />
            30.Who can tell the Developers how to do their work?
            <br /> Ans:The Developers self organise, no one tells them how to do their work <br /> <br />
            31.What skills are needed across the Developers to do their work?
            <br /> Ans:They must be cross functional <br /> <br />
            32.What is an increment?
            <br /> Ans:The result of a Sprints worth of work; potentially releasable value that builds on previous releases. <br /> <br />
            33.Who decides whether an increment should be released or not?
            <br /> Ans:The Product Owner <br /> <br />
            34.What is the best way to order a Product Backlog?
            <br /> Ans:By Value <br /> <br />
            35.Who is accountable for delivering every Sprint?
            <br /> Ans:The Scrum Team <br /> <br />
            36.Who is accountable for creating a plan for the Sprint?
            <br /> Ans:The Scrum Team in planning <br /> <br />
            37.What is the Definition of Done?
            <br /> Ans:A checklist which details what a complete piece of work looks like <br /> <br />
            38.Who creates the Definition of Done?
            <br /> Ans:The Developers or the Development Organisation <br /> <br />
            39.Who orders the Product Backlog?
            <br /> Ans:The Product Owner with support from collaborators <br /> <br />
            40.How many people manage the Product Backlog?
            <br /> Ans:The Product Owner manages the Product Backlog <br /> <br />
            41.Who can write Product Backlog Items <br /> <br />
            <br /> Ans:The Product Owner can do this themselves or delegate – the Product Owner remains accountable for clear expression of Product Backlog Items <br /> <br />
            42.Who can cancel the Sprint?
            <br /> Ans:Only the Product Owner <br /> <br />
            43.How does the Scrum Master serve the Product Owner?
            <br /> Ans:Helping find techniques for effective Product Goal definition and Product Backlog management;
            Helping the Scrum Team understand the need for clear and concise Product Backlog items;
            Helping establish empirical product planning for a complex environment; and,
            Facilitating stakeholder collaboration as requested or needed. <br /> <br />
            44.What is a Product Backlog Item?
            <br /> Ans:A unit of work in that results in value <br /> <br />
            45.What is the Product Owners responsibilities?
            <br /> Ans:Ensuring that Product Backlog Items are transparent and well understood by the developers
            Developing and explicitly communicating the Product Goal
            Creating and clearly communicating Product Backlog items
            Ordering Product Backlog items; and,
            Ensuring that the Product Backlog is transparent, visible and understood. <br /> <br />

            46.What is Backlog Refinement?
            <br /> Ans:Product Backlog refinement is the act of breaking down and further defining Product Backlog items into smaller more precise items.
            This is an ongoing activity to add details, such as a description, order, and size. Attributes often vary with the domain of work.
            <br /> <br />
            47.What is the timebox for Backlog Refinement?
            <br /> Ans:As much time as is needed <br /> <br />
            48.What is the outcome of Backlog Refinement?
            <br /> Ans:A well understood, ordered Backlog <br /> <br />
            49. What are the advantages of doing Scrum?

            <br /> Ans:The advantage of doing scrum is that while performing the test

            It minimizes the risk in response to changes made to the system
            It increases ROI ( Return of Investment)
            It improves the process continuously
            It repeatedly and rapidly looks into actual working software
            Anyone can see real working software and continue to enhance for another iteration. <br /> <br />
            50.What is a user story in Scrum?

            <br /> Ans:In scrum, a user story is short, one sentence definitions of a feature or functionality. <br /> <br />
            51. What are the artifacts of Scrum process?
            <br /> Ans:Scrum process artifacts include

            Sprint backlog
            Product backlog
            Velocity chart
            Burn-down chart <br /> <br />
            52.What is Scrum Sprint?

            <br /> Ans:Scrum project is developed in a series of “sprint”. It is a repeatable and regular work cycle in scrum methodology during which work is accomplished and kept ready for review.
            <br /> <br />
            53.What does a scrum burndown chart should consist of?

            <br /> Ans:A scrum burndown chart should consist of

            X-axis that displays working days
            Y-axis that displays remaining effort
            Ideal effort as a guideline
            Real progress of the effort
            <br /> <br />
            54.What is a scrum of scrum?

            <br /> Ans:Scrum of scrum is used to refer to the meeting after the daily scrum.  The responsible person from each agile team attends the meeting and discuss their work and answer the questions like

            Since the last meeting, what is the progress of the team?
            What your team is expected to do or should accomplish, before the next meeting?
            What are the obstacles your team faced while completing the task?
            Were you going to allow any of your work to the following team?
            <br /> <br />
            55.What is “Velocity”?

            <br /> Ans:“Velocity” is the total effort a team is capable of in a sprint.  The number is obtained by adding all the story points from the last sprint’s stories.  It is a guideline for the team to understand how many stories they can do in a sprint.
            <br /> <br />
            56.What is “Sashimi” and “Impediments”?

            <br /> Ans:Sashimi:This term is analogous to “done”; it is used to define the specific task when it is completed. The term used by a different team to refer their completed task status may differ but should remain the same within one team.
            Impediments: Any obstacle that prevents the team members from performing their work is referred to as impediments.
            <br /> <br />
            57.What is scrum poker or planning poker?
            <br /> Ans:
            Scrum poker or planning poker is a technique to estimate the relative size of development goals in software development.  It is a way to determine sprint item durations by playing number cards face down the table, instead of speaking them aloud.
            58.What does the burndown charts show?
            <br /> Ans:
            Burn down charts is used to track sprint status, they act as an early warning indicator; they can be useful in highlighting the “lack of progress”.  Also, they will highlight the area where they see redundancy.
            <br /> <br />
            59.What are your responsibilities of a professional scrum master?
            <br /> Ans:
            The scrum master role is similar to the project manager in a few cases, and the responsibilities of a scrum master are:

            Conducting Sprint planning and project management
            To schedule the daily Scrum meeting
            Management of responsibilities of the Scrum process
            Assisting development teams to follow Scrum practices
            Work to remove barriers to allow the team to focus on work
            Offering assistance with the Product Backlog.
            <br /> <br />
            60.What velocity in scrum?  How it is measured?
            <br /> Ans:
            Velocity in a scrum is a measurement of how much the team gets work done in an iteration or sprint. It is measured by

            V= Number of total story points / One iteration.
            <br /> <br />
            61.What is a story point in Scrum?
            <br /> Ans:
            Each feature in scrum is Story. A story point is an arbitrary measure used by Scrum teams, and it is a metric used by agile teams to determine the difficulty of implementing a given story.
            <br /> <br />
            62.What is Sprint in scrum?
            <br /> Ans:Sprint:It is used to define one development cycle or iterative step in a specialized agile methodology referred to as Scrum. Sprint is scrum specific, and not all forms of iterations are Sprints.
            <br /> <br />
            63.What is Iteration in Scrum?
            <br /> Ans:Iteration: It is a terminology used to define a single development cycle in general agile methods. It is a common term used in the iterative and Incremental development process.



        </div>
    )

}
export default ScrumFAQ;