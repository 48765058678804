import React from 'react'
import PropTypes from 'prop-types'
import Label from '../Label';

const InputText = (props) => {

    // let classes = "w-full my-2 bg-white rounded border border-gray-300 focus:border-royal-blue-500 focus:ring-2 focus:ring-royal-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8";
    // if (props.className) classes += props.className;

    

    const handleChange = event => {
        if (typeof props.onChange === "function") {
            props.onChange( event.target.name, event.target.value);
        }
    };
    return (
        <>
            { props.labelText && <Label>{ props.labelText }</Label> }
            <input
                type={props.type}
                id={props.id}
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                // className={classes}
                className="w-full my-2 bg-white rounded border-2 focus:border-royal-blue-500 focus:ring-1 focus:ring-royal-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                onChange={handleChange}
                {...props.rest}
            />
            { props.error && <p className="text-xs font-semibold text-red-500"> {props.error} </p> }
        </>
    )
}

InputText.propTypes = {
    labelText: PropTypes.string,
    type: PropTypes.oneOf(["text", "password"]),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
}
InputText.defaultProps = {
    labelText: "",
    type: "text",
    value: "",
    placeholder: "",
    error: ""
}

export default InputText
