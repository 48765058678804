import React from 'react'
import PropTypes from 'prop-types'
import Label from '../Label';

const InputSelect = (props) => {
    
    let classes = "w-full my-2 bg-white rounded border border-gray-300 focus:border-royal-blue-500 focus:ring-1 focus:ring-royal-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 appearance-none";

    const handleChange = event => {
        if (typeof props.onChange === "function") {
            props.onChange( event.target.name, event.target.value);
        }
    };

    return (
        <>
            { props.labelText && <Label>{ props.labelText }</Label> }
            <select id={props.id} name={props.name} className={classes} onChange={handleChange}>
                <option value="">{props.placeholder}</option>
                { props.recordList && props.recordList.map((row, index) =>
                    <option value={row.value} >{row.label}</option>
                )}  
            </select>
        </>
    )
}

InputSelect.propTypes = {
    labelText: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    recordList: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    selectedValue: PropTypes.string,
}

InputSelect.defaultProps = {
    labelText: "",
    recordList: [],
    error: ""
}

export default InputSelect
