import React from 'react'
import Header from '../Header/Header'
import HeaderTop from '../Header/HeaderTop'
import Footer from '../Footer/Footer'
import Intl from '../Intl'
import { Link } from 'gatsby'

import Box1 from '../../assets/images/desktop01.jpg'
import Box2 from '../../assets/images/desktop04.jpg'
import Box3 from '../../assets/images/desktop01.jpg'
import heroImage from '../../assets/images/desktop09.jpg'

import ArvindImg from '../../assets/images/arvind.jpg'
// import DeepaImg from '../../assets/images/deepa.jpg'
import SudarshanImg from '../../assets/images/sudarshan.jpg'
import BrijeshImg from '../../assets/images/brijesh.jpg'
import ManImg from '../../assets/images/man.png'
import ArunImg from '../../assets/images/arun.jpg'
import AasthaImg from '../../assets/images/aastha.jpg'


const AboutUsPage = () => {

    return (
        <>
            <HeaderTop />
            <Header />
            <section className="text-white body-font overflow-hidden w-full">

                <div className="container m-2 mx-auto flex  py-40  md:flex-row flex-col items-center bg-no-repeat bg-center bg-cover bg-opacity-30 w-full" style={{ backgroundImage: `url(${heroImage})` }}>
                    <div className="p-8 lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 className="title-font  mb-4 font-sans text-6xl text-center text-gray-900 ">
                            <Intl id="helloweare"></Intl>
                            <span className="text-avermass-blue-600"> VERMA GmbH</span>
                        </h1>
                    </div>
                </div>
                <div>
                    <h1 className='text-4xl text-center p-8 text-avermass-blue-500'><Intl id="ourpurpose"></Intl> </h1>
                    <div className='mt-5 flex overflow-hidden'>
                        <div className="flex sm:flex-nowrap flex-wrap bg-avermass-blue-900">
                            <div className="lg:w-2/3 md:w-1/2 m-8 bg-gray-100 shadow-2xl transition-colors text-gray-100  overflow-hidden sm:mr-10  items-end justify-start relative border-2 hover:border-white border-blue-700">
                                <img
                                    alt="AVermass"
                                    className="block h-40px w-full"
                                    src={Box1}
                                />
                            </div>
                            <div className="lg:w-1/2 md:w-1/2  flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 ">
                                <p className='m-4 text-xl p-6 font-sans'>

                                    <Intl id="ourpurposefirst"></Intl>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-5 flex'>
                    <div className="flex sm:flex-nowrap flex-wrap">

                        <div className="lg:w-1/2 md:w-1/2  flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 ">
                            <p className='m-6 text-xl p-6 font-sans'>
                                <Intl id="ourpurposesecond"></Intl>
                            </p>
                        </div>
                        <div className="lg:w-2/3 md:w-1/2 bg-gray-100 shadow-2xl transition-colors text-gray-100  overflow-hidden sm:mr-10  items-end justify-start relative border-2 border-gray-900 hover:border-blue-50">
                            <img
                                alt="AVermass"
                                className="block h-40px w-full"
                                src={Box2}
                            />
                        </div>
                    </div>
                </div>

                <section>
                    <h1 className='text-center text-3xl mt-8 pt-10 text-avermass-blue-500 border-gray-300'><Intl id="meetourteam"></Intl></h1>
                    {/* <h3>The people who make things happen in our company and who believe and build the future.</h3> */}
                    <div className=' p-10'>

                        <div className="container max-w-7xl mx-auto px-4" >

                            <div className="flex flex-wrap">
                                <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                    <div className="px-6">
                                        <img alt="John Doe" src={ArvindImg} className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2">Arvind Verma</h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Founder</p>
                                            {/* <div className="flex items-center justify-center">
                                                <button className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-full w-10 h-10 p-0 grid place-items-center text-xs leading-normal bg-transparent text-light-blue-500 hover:bg-light-blue-50 hover:text-light-blue-700 hover:bg-light-blue-50 active:bg-light-blue-100 undefined">
                                                    <a href="https://www.linkedin.com/company/verma-gmbh-consulting-it-services/" className="no-underline ml-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                        </svg>
                                                    </a>
                                                </button>
                                                <button className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-full w-10 h-10 p-0 grid place-items-center text-xs leading-normal bg-transparent text-blue-500 hover:bg-blue-50 hover:text-blue-700 hover:bg-blue-50 active:bg-blue-100 undefined">
                                                    <a href="https://www.instagram.com/verma_gmbh/?hl=en" className="no-underline ml-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                                        </svg>
                                                    </a>
                                                </button>
                                                <button className="false flex items-center justify-center gap-1 font-bold outline-none uppercase tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-full w-10 h-10 p-0 grid place-items-center text-xs leading-normal bg-transparent text-pink-500 hover:bg-pink-50 hover:text-pink-700 hover:bg-pink-50 active:bg-pink-100 undefined">
                                                    <a href="https://twitter.com/GmbhVerma" className="no-underline ml-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                        </svg>
                                                    </a>
                                                </button>
                                            </div> */}
                                            <div className="px-6 pt-4 pb-2">
                                                <Link to='#' className="mt-3 text-blue-500 inline-flex items-center "><Intl id="formore"></Intl>
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4" >
                                    <div className="px-6" >
                                        <img alt="Jack Wales" src={ManImg} className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2">Amit Patil</h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Product Manager</p>
                                            <div className="px-6 pt-4 pb-2">
                                                <Link to='#' className="mt-3 text-blue-500 inline-flex items-center "><Intl id="formore"></Intl>
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4" >
                                    <div className="px-6" >
                                        <img alt="George Hoyt" src="https://dummyimage.com/300" className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2">Srikanth Menda</h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Backend Developer</p>
                                          
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                    <div className="px-6">
                                        <img alt="Jenna Kardi" src={DeepaImg} className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2"> Deepa Sarin </h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Developer</p>
                                           
                                        </div>
                                    </div>
                                </div> */}
                                <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                    <div className="px-6">
                                        <img alt="Jenna Kardi" src={ArunImg} className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2"> Arun Sharma </h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Developer</p>
                                            <div className="px-6 pt-4 pb-2">
                                                <Link to='#' className="mt-3 text-blue-500 inline-flex items-center "><Intl id="formore"></Intl>
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                    <div className="px-6">
                                        <img alt="Jenna Kardi" src={BrijeshImg} className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2"> Brijesh Yadav </h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Frontend Developer</p>
                                            <div className="px-6 pt-4 pb-2">
                                                <Link to='#' className="mt-3 text-blue-500 inline-flex items-center "><Intl id="formore"></Intl>
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                    <div className="px-6">
                                        <img alt="Jenna Kardi" src={SudarshanImg} className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2"> Sudarshan kumar </h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Developer</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                                    <div className="px-6">
                                        <img alt="Jenna Kardi" src={AasthaImg} className="rounded-xl shadow-lg max-w-full h-auto align-middle border-none undefined" />
                                        <div className="pt-6 text-center">
                                            <h1 className="text-gray-100 text-xl font-serif font-bold leading-normal mt-0 mb-2"> Aastha Dani </h1>
                                            <p className="text-blue-gray-300 text-base font-light leading-relaxed mt-0 mb-4">Developer</p>
                                            <div className="px-6 pt-4 pb-2">
                                                <Link to='#' className="mt-3 text-blue-500 inline-flex items-center "><Intl id="formore"></Intl>
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </section>

                <div className='mt-5 flex'>
                    <div className="flex sm:flex-nowrap flex-wrap m-6" >
                        <div className="lg:w-2/3 md:w-1/2 bg-gray-100 text-gray-100  overflow-hidden sm:mr-10  items-end justify-start relative ">
                            <img
                                alt="AVermass"
                                className="block h-40px w-full"
                                src={Box3}
                            />
                        </div>
                        <div className="lg:w-1/2 md:w-1/2 text-gray-900 bg-gray-300 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 border-2 border-white hover:border-blue-700">
                            <h1 className='font-bold text-3xl text-center'> <Intl id="ourfoundes"></Intl> </h1>
                            <h3 className='text-xl text-blue-600 ml-8 mt-4'>
                                <Intl id="WheredoesthenameVERMAComefrom" />
                            </h3>
                            <div className='text-xl p-10'>
                                <Intl id="founderexplanation" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="text-bold text-gray-100 bg-gray-900 text-center m-8">
                <div>
                    <p className="font-lg text-3xl inline-flex items-center"><Intl id="interestedinourchapter"></Intl> </p>
                </div>

                <div>
                    <Link to='/' className="text-indigo-400 inline-flex items-center font-sans"><Intl id="learnmoreaboutVermaGmbH"></Intl>
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                    </Link>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AboutUsPage
