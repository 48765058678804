// import React, { useState, useEffect, useContext } from 'react'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
// import { navigate } from '@reach/router'
// import moment from 'moment';

// import InputText from '../../Core/InputText'
import Button from '../../Core/Button'
import InputOTP from '../../Core/InputOTP'
import CountDownTimer from './CountDownTimer'
// import GlobalContext from '../../../context/GlobalContext'
import AuthServices from '../../../services/auth/AuthServices'

const VerifyEmail = ({ location }) => {
    const params = new URLSearchParams(location.search);
    const input = { "email": params.get("email"), "otp": params.get("otp") }

    // const gContext = useContext(GlobalContext);

    const [isLoading, setIsLoading] = useState(true);
    const [record, setRecord] = useState(input);
    const [recordError, setRecordError] = useState({});
    const [expiredAt, setExpiredAt] = useState();
    const [isVerified, setIsVerified] = useState(false);

    const onHandleChange = (name, value) => {
        setRecord({ ...record, [name]: value });

        console.log(record)
    };

    useEffect(() => {
        AuthServices.initiateVerifyEmail(record).then((response) => {

            setExpiredAt(response.otp_expired_in)
            setIsLoading(false)
            console.log(response)
        }).catch((error) => { 
            setRecordError(error.response.data);
            setIsLoading(false) 
        });

    }, []);

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (isLoading) {
            return null;
        }
        setIsLoading(true);
        AuthServices.verifyEmail(record).then((response) => {
            console.log(response)
            setIsVerified(true)
            setIsLoading(false);
            // navigate('/app/dashboard')
        }).catch((error) => {
            setRecordError(error.response.data);
            setIsLoading(false);
        });
    };

    return (

        <>
            <section className="text-gray-800 body-font bg-gray-100 py-12">
                <div className="container px-5 py-2 mx-auto flex flex-wrap items-center">
                    <div className="lg:w-2/6 md:w-1/2 mx-auto rounded-lg px-8 py-4 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-white border border-gray-300 shadow">

                        <h1 className="text-center text-xl md:text-2xl font-bold leading-tight my-6">Verify Email</h1>

                        {!isVerified ?
                            <>
                                <p className="text-center">Enter the OTP you received at {record.email || ""}</p>

                                <form className="mt-4" action="#" method="POST" onSubmit={onFormSubmit}>
                                    {recordError.message && (
                                        <div
                                            className="mb-4 bg-red-400 rounded-md text-gray-100 px-4 py-3 shadow-md"
                                            role="alert"
                                        >
                                            <div className="flex font-semibold space-x-4 items-center">

                                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 text-gray-100 font-bold" viewBox="0 0 16 16">
                                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                                                </svg>
                                                <p className="">{recordError.message || ""}</p>

                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <InputOTP
                                            id="otp"
                                            name="otp"
                                            placeholder="****"
                                            value={record.otp || ""}
                                            onChange={onHandleChange}
                                            isInputSecure={false}
                                        />
                                        { recordError && recordError.otp &&  <p className="text-center text-md mb-2 font-semibold text-red-500"> {recordError.otp} </p> }
                                    </div>

                                    <div className="w-full p-2 text-center font-semibold">
                                        <CountDownTimer remainingMilliseconds={expiredAt * 1000} />
                                    </div>

                                    <div className="text-right mt-2">
                                        <Link to="/app/verify-email" className="text-sm font-semibold text-royal-blue-500 hover:text-royal-blue-700 focus:text-royal-blue-700">Resend OTP </Link>
                                    </div>

                                    <Button type="submit" className="w-full"> Verify Now </Button>
                                </form>
                            </>
                            :
                            <div className="text-center">
                                <p className="flex mb-2 leading-relaxed justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-green-700 w-16" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                </p>
                                <p className="text-md font-bold text-green-700 mt-2 mb-8">
                                    Successfully Verified
                                </p>
                                <Link to="/app/login" className="font-semibold rounded-lg px-4 py-3 mt-6 bg-royal-blue-500 hover:bg-royal-blue-600 focus:bg-royal-blue-600 text-white"> Login </Link>
                            </div>
                        }
                    </div>
                </div>
            </section>

        </>
    )
}

export default VerifyEmail
