import React, { useEffect,useContext } from 'react';
import axios from 'axios';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from '@paypal/react-paypal-js';
import Spinner from '../../Spinner';
import GlobalContext from '../../../../context/GlobalContext'

const PayPalButtonWrapper = ({ currency, showSpinner, amount, style, onPopupClose }) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const {userProfile} = useContext(GlobalContext);
  const d = new Date();
  let ISO_Date = d.toISOString();  

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency: currency
      }
    });
  }, [currency, showSpinner]);
  
  const params ={
    "id": "9EA5808828617250V", 
    "intent": "CAPTURE",       
    "status": "COMPLETED",      
    "payment_gateway": "paypal",
    "purchase_units": [
        {
            "reference_id": "default", 
            "amount": {
                "currency_code": currency ? currency.toString() : "",
                "value": amount ? amount.toString() : ""
            },
            "payee": {
                "email_address": userProfile.email ? userProfile.email : "", 
                "merchant_id": "AJLCK2V35LPFE" 
            },
            "soft_descriptor": "PAYPAL *PYPLTEST", 
            "shipping": {
                "name": {
                    "full_name": userProfile.first_name || userProfile.last_name ? (userProfile.first_name ? userProfile.first_name : "") + " " + (userProfile.last_name ? userProfile.last_name : "") : "" // user info
                },
                "address": {
                    "address_line_1": "",
                    "address_line_2": "",
                    "admin_area_2": userProfile.city ? userProfile.city : "",
                    "admin_area_1": userProfile.state ? userProfile.state : "",
                    "postal_code": userProfile.pincode ? userProfile.pincode : "" , 
                    "country_code": userProfile.country.country_code ? userProfile.country.country_code : ""
                }
            },
            "payments": {
                "captures": [
                    {
                        "id": "31K9272559040534R", 
                        "status": "COMPLETED",  
                        "amount": {
                          "currency_code": currency ? currency.toString() : "",
                          "value": amount ? amount.toString() : ""
                      },
                        "final_capture": true,
                        "seller_protection": {
                            "status": "ELIGIBLE",
                            "dispute_categories": [
                                "ITEM_NOT_RECEIVED",
                                "UNAUTHORIZED_TRANSACTION"
                            ]
                        },
                        "create_time": ISO_Date,
                        "update_time": ISO_Date
                    }
                ]
            }
        }
    ],
    "payer": {
        "name": {
            "given_name": userProfile.first_name ? userProfile.first_name : "",
            "surname": userProfile.last_name ? userProfile.last_name : ""
        },
        "email_address": userProfile.email ? userProfile.email : "",
        "payer_id": "QRH7UY6VXYC7Q",
        "phone": {
            "phone_number": {
                "national_number": ""
            }
        },
        "address": {
          "address_line_1": "",
          "address_line_2": "",
          "admin_area_2": userProfile.city ? userProfile.city : "",
          "admin_area_1": userProfile.state ? userProfile.state : "",
          "postal_code": userProfile.pincode ? userProfile.pincode : "" , 
          "country_code": userProfile.country.country_code ? userProfile.country.country_code : ""
      }
    },
    "create_time": ISO_Date,
    "update_time": ISO_Date,
    "links": [
        {
            "href": "https://api.sandbox.paypal.com/v2/checkout/orders/9EA5808828617250V",
            "rel": "self",
            "method": "GET"
        }
    ]
  }

  const headers = {
    'Authorization': "Basic c3Jpa2FudGg0OnNyaWthbnRo",
    'Content-Type': 'application/json'
  }
  const paymentTransaction = ()=> {
    
    axios.post('https://forgery-detection-backend-xhmiwfoy2a-uc.a.run.app/api/v1/subscriptions/payment_transaction', params,{headers})
      .then(function (response) {
        
        if(response.status == 201){
          alert("payment successful and account updated")
        }else{
          alert("payment unsuccessful, something went wrong")
          
        }
        onPopupClose();
      })
      .catch(function (error) {
        console.log(error);
        onPopupClose();
      });
  }


  return (
    <>
      {showSpinner && isPending && <Spinner />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount
                  }
                }
              ]
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then((resData) => {
            console.log('PayPal Payment Success', resData);
            paymentTransaction();
            
            
          });
        }}
        onCancel={() => {
          console.log('Payment has been cancelled');
          onPopupClose();
        }}
        onError={(error) => {
          console.log('Payment Error: ', error);
          onPopupClose();
        }}
      />
    </>
  );
};

function PayPal(props) {
  const { close,amount,currency } = props;
  let int = amount+".00"
  
  return (
    <div style={{ maxWidth: '750px', minHeight: '200px' }}>
      <PayPalScriptProvider
        options={{
          'client-id': 'test'
        }}
      >
        <PayPalButtonWrapper
          currency={currency}
          showSpinner={false}
          amount={int}
          style={{ layout: 'horizontal', label: 'pay', tagline: true }}
          onPopupClose={close}
        />
      </PayPalScriptProvider>
    </div>
  );
}

export default PayPal;
