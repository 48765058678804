import React, { useState } from 'react'
// import { Link } from 'gatsby'
import Intl from '../../Intl'
import AppHeader from '../../Header/AppHeader'
import Sidebar from '../../Sidebar/Sidebar'
// import SpeechtoText from './SpeechtoText'
// import AudioScrum from './AudioScrum'
// import df-messenger from 'dialogflow'
const ForgeryDetection = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <>
            <div className="flex h-screen overflow-hidden">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area starts */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <AppHeader />

                    <section className="text-gray-600">
                        <div className="container mx-auto flex px-5 py-6 items-center justify-center flex-col">
                            <div className="text-center p-4 w-full">
                                <div className="flex items-center justify-center">
                                    {/* <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-300">E2E Forgery Detection</h1> */}
                                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-300"><Intl id="ScrumMaster" /></h1>
                                </div>

                                <p className="leading-relaxed text-gray-400"><Intl id="ScrumMasterExplanation" /></p>


                            </div>
                        </div>
                    </section>
                    <div className='flex justify-center items-center p-6'>
                        {/* <iframe
                            allow="microphone;"
                            width="350"
                            height="430"
                            src="https://www.gstatic.com/dialogflow-console/fast/messenger-cx/bootstrap.js?v=1">
                        </iframe> */}
                        
                        <script src='https://www.gstatic.com/dialogflow-console/fast/messenger-cx/bootstrap.js?v=1'></script>
                        <df-messenger
                            df-cx="true"
                            location="asia-south1"
                            chat-title="ScrumMaster"
                            agent-id="55ce2f99-71b0-4409-865f-033aef60002f"
                            language-code="en"
            
                        >   
                        </df-messenger>
                    </div>



                    {/* <SpeechtoText /> */}
                    {/*               <AudioScrum /> */}
                </div>
                {/* Content area ends */}

            </div>


        </>
    )
}

export default ForgeryDetection
