import React from 'react';
import Modal from '../Modal/Modal';
import GooglePay from '../PaymentButton/GooglePay/GooglePay';
import PayPal from '../PaymentButton/PayPal/PayPal';
import Razorpay from '../PaymentButton/RazorPay/Rozarpay';

function PaymentModel({ open, close, title, amount }) {
  
  return (
    <Modal open={open} close={close} title={title}>
      <GooglePay amount={amount} currency="USD" />
      <PayPal amount={amount} currency="USD" />
      <Razorpay amount={amount ? amount : 71} currency="USD" />
    </Modal>
  );
}

export default PaymentModel;
