import React from 'react'
import PropTypes from 'prop-types'

const Label = (props) => {
    return (
        <label className="block text-gray-700">{ props.children }</label>
    )
}

Label.propTypes = {
    children: PropTypes.string,
}

export default Label
