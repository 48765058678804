import React from 'react'
import PropTypes from 'prop-types'
import Countdown, { zeroPad } from "react-countdown";

const CountDownTimer = (props) => {

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>Your verification time expired!</span>;
        } else {
            // Render a countdown
            return <span>Your OTP will be expired in {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
    };

    return (
        <Countdown date={Date.now() + props.remainingMilliseconds} renderer={renderer} />
    )
}

CountDownTimer.propTypes = {
    remainingMilliseconds: PropTypes.number.isRequired,
}
CountDownTimer.defaultProps = {
    remainingMilliseconds: (60 * 60 * 1000), // 60 minutes
}
export default CountDownTimer
