// import React, {useState, useEffect, useContext} from 'react'
// import PropTypes from 'prop-types'
import React, {useState,  useContext} from 'react'
import { navigate } from '@reach/router'

import Intl from '../../Intl'
// import Button from '../../Core/Button'

import GlobalContext from '../../../context/GlobalContext'
import AuthServices from '../../../services/auth/AuthServices'

const DeactivateAccount = props => {

    const gContext = useContext(GlobalContext)
    const [isLoading, setIsLoading] = useState(false);

    const handleDeactivateAccount = () => {
        setIsLoading(true);
        AuthServices.deactivateAccount().then((response) => {
            console.log(response)
            gContext.logout();
            navigate('/app/login')
            setIsLoading(false);
            
        }).catch((error) => {
            // console.log(error.response.data)
        });
    };

    return (

        <>
            
            <p> <Intl id="deactiveAccountExplanation" /></p>

            <button type="button" className="bg-royal-blue-600 hover:bg-royal-blue-900 focus:bg-royal-blue-600 text-white font-semibold rounded-lg px-4 py-3 mt-6" onClick={ (e)=> handleDeactivateAccount()}> <Intl id="deactivateMyAccount" /> </button>
            

        </>
    )

}

DeactivateAccount.propTypes = {

}

export default DeactivateAccount
