import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import Button from '../../Core/Button'
import InputText from '../../Core/InputText'
import Intl from '../../Intl'

import GlobalContext from '../../../context/GlobalContext'
import AuthServices from '../../../services/auth/AuthServices'
import InputSelect from '../../Core/InputSelect'

import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions'
import Modal from '../../Core/Modal/Modal'

import useTranslate from '../../../hooks/useTranslate'

const Signup = () => {
    // const [translate] = useTranslate();
    const [translate] = useTranslate();

    const gContext = useContext(GlobalContext);
    if (gContext.isLoggedIn) navigate('/app/dashboard')
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState({});
    const [recordError, setRecordError] = useState({});
    const [agree, setAgree] = useState(false);
    const [warn, setWarn] = useState(false);

    // Modal status
    const [isOpen, SetIsOpen] = useState(false)

    // Close Modal function
    const closeModal = () => SetIsOpen(false)

    const countryList = [
        { value: "india", label: "India" },
        { value: "germany", label: "Germany" },
        { value: "canada", label: "Canada" }
    ]

    const onHandleChange = (name, value) => {
        // console.log(name,value);
        setRecord({ ...record, [name]: value });
    };

    // const btnHandler = () => {
    //     console.log(record);
    // }




    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        setWarn(false);
        // Don't miss the exclamation mark
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        console.log(record);
        if (!agree) {
            setWarn(true);
        }
        if (isLoading) {
            return null;
        }
        setIsLoading(true);
        AuthServices.signup(record).then((response) => {
            console.log(response)
            setRecord({});
            setRecordError({});
            setIsLoading(false);
            navigate('/app/login')
        }).catch((error) => {
            // console.log(error.response.data)
            setRecordError(error.response.data);
            setIsLoading(false);
        });
    };






    return (

        <>
            <section className="text-gray-800 body-font bg-gray-900 py-12">
                <div className="container px-5 py-2 mx-auto flex justify-between  flex-wrap items-center">
                    <div className="lg:w-3/6 md:w-1/2 mx-auto  px-8 py-4 flex  flex-col md:ml-auto w-full mt-10 md:mt-0 bg-gray-300 border-2 border-white shadow">
                        <h1 className="text-xl md:text-2xl font-bold leading-tight"><Intl id="joinusforfree"></Intl></h1>
                        <form className="mt-6 " action="#" method="POST" onSubmit={onFormSubmit}>
                            <p>
                                {recordError.message && (
                                    <p className="text-xs font-semibold text-red-500">
                                        {recordError.message || ""}
                                    </p>
                                )}
                            </p>
                            <div className="flex w-full space-x-4 py-1 justify-around">
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="firstname" />} required id="first_name" name="first_name" value={record.first_name || ""} placeholder={translate("enterFirstName")} onChange={onHandleChange} error={recordError.first_name && recordError.first_name || ""} />
                                </div>
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="lastname" />} required id="last_name" name="last_name" value={record.last_name || ""} placeholder={translate("enterLastName")} onChange={onHandleChange} error={recordError.last_name && recordError.last_name || ""} />
                                </div>
                            </div>

                            <div className="flex w-full space-x-4 py-1 justify-around">
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="username" />} id="username" name="username" value={record.username || ""} placeholder={translate("enterUsername")} onChange={onHandleChange}  error={recordError.username && recordError.username || ""} />
                                </div>
                                <div className='w-1/2'>
                                    <InputText labelText="Email" id="email" type='mail' name="email" value={record.email || ""} placeholder={translate("enterEmail")} onChange={onHandleChange} required error={recordError.email && recordError.email || ""} />
                                </div>
                            </div>

                            <div className="flex w-full space-x-4 py-1 justify-around">
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="password" />} type="password" id="password" name="password" value={record.password || ""} placeholder={translate("enterPassword")} onChange={onHandleChange} required error={recordError.password && recordError.password || ""} />
                                </div>
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="confirmpassword" />} type="password" id="confirm_password" name="confirm_password" value={record.confirm_password || ""} placeholder={translate("enterConfirmPassword")} required onChange={onHandleChange} error={recordError.confirm_password && recordError.confirm_password || ""} />
                                </div>
                            </div>

                            <div className="flex w-full space-x-4 py-1 justify-around">
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="city" />} id="city" name="city" value={record.city || ""} onChange={onHandleChange} placeholder={translate("enterCity")} required  error={recordError.city && recordError.city || ""} />
                                </div>
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="state" />} id="state" name="state" value={record.state || ""} onChange={onHandleChange} placeholder={translate("enterState")} required error={recordError.state && recordError.state || ""} />
                                </div>
                            </div>

                            <div className="flex w-full space-x-4 py-1 justify-around">
                                <div className="w-1/2 test-xl">
                                    <InputSelect labelText={<Intl id="country" />} id="country" name="country" value={record.country || ""} onChange={onHandleChange} placeholder={translate("selectCountry")} required recordList={countryList} error={recordError.country && recordError.country || ""}/>
                                </div>
                                <div className='w-1/2'>
                                    <InputText labelText={<Intl id="pincode" />} type='text' id="pincode" name="pincode" value={record.pincode || ""} onChange={onHandleChange} placeholder={translate("enterPincode")} required error={recordError.pincode && recordError.pincode || ""} />
                                </div>
                            </div>

                            <div>
                                <div className='ml-4'>
                                    <input type="checkbox" id="agree" onChange={checkboxHandler} />
                                    <label htmlFor="agree"> <Intl id="readandagree" />
                                        <span onClick={() => { SetIsOpen(true) }} activeClassName="border-b-2 border-gray-200" className=" hover:text-gray-900 font-mono text-lg text-blue-800 ml-2"><Intl id="termsandconditons"></Intl></span>

                                    </label>
                                </div>

                                {warn && <p className='text-red-500'> * <Intl id="pleseAcceptTheTermsAndCondition" /></p>}



                                {/* signup-modal */}

                                <Modal
                                    open={isOpen}
                                    close={closeModal}
                                    title={<Intl id="termsandconditons"></Intl>}
                                    fade={true}
                                    backdrop={true}
                                    fullscreen={"true"}
                                    centered={false}
                                    scrollable={true}
                                    transition={true}
                                    cancel={<Intl id="agree"></Intl>}
                                >
                                    <TermsAndConditions />
                                </Modal>




                                <Button type="submit" className="w-full cursor-pointer"> <Intl id="register" /> </Button>

                            </div>
                            {/* <Button disabled={!agree} type="submit" className="w-full cursor-pointer" onClick={btnHandler}> Register </Button> */}
                        </form>
                        <p className="mt-8 text-center"><Intl id="alreadyhaveanaccount" /> <Link to="/app/login" className="text-blue-500 hover:text-blue-700 font-semibold"><Intl id="signin" /></Link></p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Signup
