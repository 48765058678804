import React from 'react'
// import PropTypes from 'prop-types'
import Intl from '../../Intl'

const ChangePasswordSuccess = props => {
    return (
        <>
            <div className="text-center">
                <p className="flex mb-2 leading-relaxed justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-green-700 w-16" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                </p>
                <p className="text-md font-bold text-green-700 mt-2 mb-8">
                    <Intl id="passwordUpadtedSucessfully" />
                </p>

            </div>
        </>
    )
}

ChangePasswordSuccess.propTypes = {

}

export default ChangePasswordSuccess
