import React, {useState, useContext} from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'

import Intl from '../../Intl'

import InputText from '../../Core/InputText'
import Button from '../../Core/Button'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import GlobalContext from '../../../context/GlobalContext'
import AuthServices from '../../../services/auth/AuthServices'

import useTranslate from '../../../hooks/useTranslate'

const ForgotPassword = props => {

    const [translate] = useTranslate();

    const gContext = useContext(GlobalContext);
    if (gContext.isLoggedIn) navigate('/app/dashboard')

    
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState({});
    const [recordError, setRecordError] = useState({});

    const onHandleChange = (name, value) => {
        setRecord({ ...record, [name]: value });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (isLoading) {
            return null;
        }
        setIsLoading(true);
        AuthServices.forgotPassword(record).then((response) => {
            console.log(response)
            setRecord({});
            setRecordError({});
            navigate('/app/forgot-password-success')
            setIsLoading(false);
            
        }).catch((error) => {
            // console.log(error.response.data)
            setRecordError(error.response.data);
            setIsLoading(false);
        });
    };

    
    const responseGoogle = (response) => {
        console.log(response);
    }    
    const responseFacebook = (response) => {
        console.log(response);
    }
    return (

        <>
            <section className="text-gray-800 body-font bg-gray-900 py-4">
                <div className="container px-5 py-2 mx-auto flex flex-wrap items-center">
                    <div className="lg:w-2/6 md:w-1/2 mx-auto  px-8 py-4 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-gray-300 border-2 border-gray-300 shadow">

                        <h1 className="text-xl md:text-2xl font-bold leading-tight"><Intl id="forgotyourpassword" /> </h1>
                        <p className="py-2"> <Intl id="forgotyourpasswordExplanation" /> </p>

                        <form className="mt-2" action="#" method="POST" onSubmit={onFormSubmit}>
                            {recordError.detail && (
                                <div
                                    className="mb-4 bg-red-200 rounded-b text-red-900 px-4 py-3 shadow-md"
                                    role="alert"
                                >
                                    <div className="flex">
                                        <div>
                                            <p className="text-sm">{recordError.detail || ""}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div>
                                <InputText labelText={<Intl id="Email"/>} id="email" name="email" value={record.email || ""} placeholder={translate("enterEmail")} onChange={onHandleChange} error={ recordError.email && recordError.email || ""} />
                            </div>


                            <div className="text-right mt-2">
                                <Link to="/app/login" className="text-sm font-semibold text-gray-700 hover:text-blue-700 focus:text-blue-700"><Intl id="alreadyhaveanaccount" /></Link>
                            </div>

                            <Button type="submit" className="w-full"> <Intl id="resetpassword" /> </Button>
                        </form>

                        <hr className="my-6 border-gray-300 w-full" />

                        <div className="flex w-full space-x-4 text-center">
                            {/* <Button className="flex w-1/2 bg-blue-900 text-white border border-gray-200 py-3 px-5 shadow rounded-md items-center space-x-2 justify-center"> 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                </svg>                                    
                                <span>Facebook</span>
                            </Button> */}
                            <FacebookLogin
                                appId="1088597931155576"
                                fields="name,email,picture"
                                render={renderProps => (
                                    <button className="flex w-1/2 bg-blue-900 text-white border border-gray-200 py-3 px-5 shadow rounded-md items-center space-x-2 justify-center font-semibold mt-6 "
                                        onClick={renderProps.onClick} 
                                        disabled={renderProps.disabled}> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                        </svg>                                    
                                        
                                        <span>Facebook</span>
                                    </button>                                    
                                )}                                
                                callback={responseFacebook} />                        

                            {/* <Button buttonStyle="custom" to="" className="flex w-1/2 bg-red-500 text-white border border-gray-200 py-3 px-5 shadow rounded-md items-center space-x-2 justify-center"> 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6" viewBox="0 0 16 16">
                                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                </svg>                            
                                <span>Google</span>
                            </Button> */}
                            <GoogleLogin
                                clientId="180857662363-39r3dlmo5sg9b275lr17s4ep593kc4hm.apps.googleusercontent.com"
                                render={renderProps => (
                                    <button className="flex w-1/2 bg-red-500 text-white border border-gray-200 py-3 px-5 shadow rounded-md items-center space-x-2 justify-center font-semibold mt-6 "
                                        onClick={renderProps.onClick} 
                                        disabled={renderProps.disabled}> 
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6" viewBox="0 0 16 16">
                                            <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                        </svg>                            
                                        
                                        <span>Google</span>
                                    </button>
                                )}
                                buttonText="Login"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>

                        <p className="mt-8"><Intl id="NeedAnAcoount"></Intl><Link to="/app/signup" className="text-blue-500 hover:text-blue-700 font-semibold"><Intl id="createAnAccount"></Intl></Link></p>

                    </div>
                </div>
            </section>

        </>
    )

}

ForgotPassword.propTypes = {

}

export default ForgotPassword
