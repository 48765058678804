import React, { useState } from 'react'
import { Link } from 'gatsby'
import Intl from '../../Intl'

import AppHeader from '../../Header/AppHeader'
import Sidebar from '../../Sidebar/Sidebar'

const Dashboard = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <>
            <div className="flex h-screen overflow-hidde">
                
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area starts */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <AppHeader />


                    <section className="text-gray-600 body-font">
                        <div className="container px-5 py-12 mx-auto">
                            <div className="flex flex-wrap w-full mb-20">
                                <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-100">
                                        <Intl id="dashboard" />
                                    </h1>
                                    <div className="h-1 bg-avermass-blue-500 rounded"></div>
                                </div>
                            </div>

                            <div className="flex flex-wrap -m-4">



                                <div className="xl:w-1/3 md:w-1/2 p-4">
                                        <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute rounded-bl z-10">
                                            <Intl id="comingsoon"></Intl>
                                        </span>
                                    <div className="bg-gray-100 p-6 rounded-lg">
                                        <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" />
                                        
                                        <h3 className="tracking-widest text-avermass-blue-500 text-xs font-medium title-font"><Intl id="Text" ></Intl></h3>
                                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4" ><Intl id="textForgeryDetection" /></h2>

                                        <p className="leading-relaxed text-base">
                                            <Intl id="textforgeryDetectiondashboardExplanation" />
                                        </p>

                                        <p className="p-4 flex items-center justify-center">
                                            <Link to="/app/forgery-detection" className="px-4 py-2 text-white bg-avermass-blue-500 rounded shadow-xl" ><Intl id="scanNow" /></Link>
                                        </p>
                                    </div>
                                </div>


                                <div className="xl:w-1/3 md:w-1/2 p-4">
                                    <div className="bg-gray-100 p-6 rounded-lg">
                                        <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" />
                                        <h3 className="tracking-widest text-avermass-blue-500 text-xs font-medium title-font"><Intl id="scanImagesVideos"></Intl></h3>
                                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4"><Intl id="FaceForgeryDetection" /></h2>
                                        
                                        <p className="leading-relaxed text-base">
                                            <Intl id="faceforgeryDetectiondashboardExplanation" />
                                        </p>

                                        <p className="p-4 flex items-center justify-center">
                                            <Link to="/app/face-forgery-detection" className="px-4 py-2 text-white bg-avermass-blue-500 rounded shadow-xl" > <Intl id="scanNow" /></Link>
                                        </p>
                                    </div>
                                </div>


                                {/* <div className="xl:w-1/4 md:w-1/2 p-4"></div> */}

                                <div className="xl:w-1/3 md:w-1/2 p-4">
                                    <div className="bg-gray-100 p-6 rounded-lg">
                                        <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" />
                                        <h3 className="tracking-widest text-avermass-blue-500 text-xs font-medium title-font"><Intl id="scanImages" /></h3>
                                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4"><Intl id="SignatureForgeryDetection" /></h2>

                                        <p className="leading-relaxed text-base">
                                            <Intl id="signatureforgeryDetectiondashboardExplanation" />
                                        </p>

                                        <p className="p-4 flex items-center justify-center">
                                            <Link to="/app/signature-forgery-detection" className="px-4 py-2 text-white bg-avermass-blue-500 rounded shadow-xl" > <Intl id="scanNow" /></Link>
                                        </p>
                                    </div>
                                </div>

                                <div className="xl:w-1/3 md:w-1/2 p-4">
                                    <div className="bg-gray-100 p-6 rounded-lg">
                                        <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" />
                                        <h3 className="tracking-widest text-avermass-blue-500 text-xs font-medium title-font"><Intl id="voice" /></h3>
                                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4"><Intl id="scrumMaster" /></h2>

                                        <p className="leading-relaxed text-base">
                                        <Intl id="scrummasterdashboardExplanation" />
                                        </p>

                                        <p className="p-4 flex items-center justify-center">
                                            <Link to="/app/scrummaster" className="px-4 py-2 text-white bg-avermass-blue-500 rounded shadow-xl" > <Intl id="openNow" /></Link>
                                        </p>
                                    </div>
                                </div>


                                {/* <div className="xl:w-1/3 md:w-1/2 p-4">
                                    <div className="bg-gray-100 p-6 rounded-lg">
                                        <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" />
                                        <h3 className="tracking-widest text-avermass-blue-500 text-xs font-medium title-font">Scan Images</h3>
                                        <h2 className="text-lg text-gray-900 font-medium title-font mb-4" >E2E Forgery Detection</h2>

                                        <p className="leading-relaxed text-base">
                                            End-To-End deep neural network predicting forgery masks to the image copy-move forgery detection problem. We use a convolutional neural network  for an image.
                                        </p>

                                        <p className="p-4 flex items-center justify-center">
                                            <Link to="/app/forgery-detection" className="px-4 py-2 text-white bg-avermass-blue-500 rounded shadow-xl" > Scan Now</Link>
                                        </p>
                                    </div>
                                </div> */}
                                

                            </div>
                        </div>
                    </section>


                </div>
                {/* Content area ends */}

            </div>
            {/*             
            <HeaderTop />
            <Header /> */}

            {/* 
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap w-full mb-20">
                        <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Dashboard</h1>
                            <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                        </div>
                        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                    </div>
                    <div className="flex flex-wrap -m-4">
                        <div className="xl:w-1/4 md:w-1/2 p-4">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">Scan Images</h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">E2E Forgery Detection</h2>
                                <p className="leading-relaxed text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <p className="p-4 flex items-center justify-center">
                                    <Link to="/app/forgery-detection" className="px-4 py-2 text-white bg-blue-500 rounded shadow-xl" > Scan Now</Link>
                                </p>
                            </div>
                        </div>

                        <div className="xl:w-1/4 md:w-1/2 p-4">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <img className="h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/720x400" alt="content" />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">Scan Images, Videos</h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">Face Forgery Detection</h2>
                                <p className="leading-relaxed text-base">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <p className="p-4 flex items-center justify-center">
                                    <Link to="/app/face-forgery-detection" className="px-4 py-2 text-white bg-blue-500 rounded shadow-xl" > Scan Now</Link>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

 */}

            {/* <Footer /> */}

        </>
    )
}

export default Dashboard
