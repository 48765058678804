import React from 'react'
import PropTypes from 'prop-types'

const InputOTPItem = (props) => {
    const handleChange = event => {
        if (typeof props.onChange === "function") {
            props.onChange(props.index, event.target.value);
        }
    }
    return(
        <>
            <div>
                <input type={ (props.isInputSecure) ? 'password': 'text' } id={props.id} name={props.name} value={ props.value ? (props.value.trim()) : ''} 
                    className="w-20 my-2 bg-white rounded border border-gray-300 focus:border-royal-blue-500 focus:ring-1 focus:ring-royal-blue-200 outline-none text-gray-700 py-1 px-3 text-4xl text-center "
                    onChange={handleChange}
                    maxLength="1"
                    onFocus={(e) => {
                        e.target.select();
                    }}
                    placeholder="*"
                />
            </div>
        </>
    )
}

const InputOTP = (props) => {

    // const [otp, setOtp] = useState(props.value && props.value);
    const otp = props.value
    let otpArray = (otp.toString().split(''));
    let OTPItems = [];

    const onValueChange = (index, value) => {
        let newOtpArray = (otp.toString().split(''));
        newOtpArray[index] = (value ? value : ' ')
        const newOtp = newOtpArray.join('');

        props.onChange('otp', newOtp);
        if(value){
            setFocus(index, 'next')
        }
    };
    const setFocus = (index, position) => {
        if(position === 'next'){
            if(index < props.numOfInputs -1){
                const nextSibling = document.querySelector(`input[name=otp_${index + 1}]`);        
                nextSibling.focus()
            }
        }else{
            if(index > 0){
                const prevSibling = document.querySelector(`input[name=otp_${index - 1}]`);        
                prevSibling.focus()
            }
        }
    }

    for (var i = 0; i < props.numOfInputs; i++) {
        OTPItems.push(
            <InputOTPItem 
                id={`otp_${i}`}
                name={`otp_${i}`}
                index={i}
                key={i}
                value={otpArray && otpArray[i]}
                onChange={onValueChange}
                isInputSecure={props.isInputSecure}
            />
        );
    }   
    
    return(
        <>
            <div className="flex justify-center space-x-4" >
                { OTPItems }
            </div>
        </>
    )
}

InputOTP.propTypes = {
    labelText: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,

    numOfInputs: PropTypes.number,
    isInputSecure:PropTypes.bool,
}

InputOTP.defaultProps = {
    numOfInputs: 4,
    isInputSecure: false,
    error: "",
    value: ""
}
export default InputOTP;