import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import HeaderTop from '../Header/HeaderTop'
import Accordion from '../Core/Accordion/Accordion'

import Modal from '../Core/Modal/Modal'
import ScrumFAQ from '../Core/Accordion/ScrumFAQ'

import BgImage from '../../assets/images/desktop07.jpg'

import Intl from '../Intl'

import useTranslate from '../../hooks/useTranslate'

// import { ServiceData as Data } from './ServiceData'
// import { productData as Data } from './ProductData'


const ServicePage = () => {
    const [translate] = useTranslate();
    const [value, setValue] = useState();
    const productData = translate('productData', { isArray: true });
    useEffect(() => {
        setValue(productData);
    },[])
    console.log("productData",productData);

    const searchFilter = (e) => {
        const filtered = productData.filter((data) => {
            return data.title.includes(e.target.value) || data.body.includes(e.target.value)
        }) 
        setValue(filtered);
    }

      // Modal status
      const [isOpen, SetIsOpen] = useState(false)

      // Close Modal function
      const closeModal = () => SetIsOpen(false)

    return (
        <> 
            <HeaderTop />
            <Header />

            <section className="text-gray-100 body-font overflow-hidden">
                <div className="container mx-auto m-2  flex px-2 py-20 items-center bg-no-repeat bg-center bg-cover opacity-80" style={{ backgroundImage: `url(${BgImage})`}}>
                        <div className="mx-80 place-items-center self-center items-center text-center">
                        
                            <h1 className=" z-4 flex title-font font-serif justify-center place-items-center self-center text-avermass-blue-600  mb-4 font-bold text-5xl">
                                <Intl id="howcanwehelpyou"></Intl>
                            </h1>
                            <form className=' relative flex opacity-95'  id='search-form' action='' >
                                <input className='bg-transparent text-gray-900 placeholder-gray-900 placeholder-opacity-90 text-2xl text-center  border-white border-2 shadow-red-500 shadow-md  font-2 w-full h-10 outline-none' placeholder='Describe your issue'  aria-label='Describe your issue to find information that might help you.' autoComplete='off' name='q' spellCheck='false' aria-autocomplete='both' dir='ltr' onChange={(e) => searchFilter(e)}>
                                </input>
                                <button>
                                    <svg className="bg-blue-700 border-none cursor-pointer top-0 w-10" viewBox="0 0 24 24">
                                        <path d="M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z">
                                        </path>
                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                    </svg>
                                </button>
                            </form>
                            <p className='mt-6 opacity-100 text-blue-900 z-32 text-xl from-indigo-900 font-semibold'>
                            <Intl id="serachbarexplanation"></Intl> 
                            </p>
                                                        
                        </div>
                </div>
            </section>

            <h1 className='text-light-blue-accent-700 text-3xl text-center p-10'><Intl id="awesomeFeatures"></Intl></h1>
            <section className="flex justify-center ">
                
                <>

                <div className="container mx-auto font-sans  lg:grid grid-cols-3 gap-3 py-4  flex justify-center container  md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    
                    {value?.map((productData)=>{
                        return (


                            <div className="group block max-w-xs mx-auto rounded-lg p-6 bg-sky-800 ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-blue-500 hover:ring-sky-300">
                        
                                <h4 className="py-4 text-2xl font-sans text-gray-100 hover:text-black  ">{productData?.title}</h4>
                                <p className="font-lg text-gray-300  hover:text-black font-sans ">{productData?.body}</p>

                            
                                <div className="mt-3 text-indigo-800 inline-flex items-center">
                                    <Link to="" activeClassName="border-b-2 border-gray-200 font-sans" className=" hover:text-gray-900 font-sans text-sm "><Intl id="viewall"></Intl></Link> 
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                </div>
                        
                            </div>
                        )
                        })}
                    
                </div>
                </>
            </section>

            <h1 className='text-center text-light-blue-accent-700 text-3xl mt-10'><Intl id="quicktipsandtopics"></Intl></h1>
            <section className='text-gray-100 body-font overflow-hidden flex p-6'>               
                <>
                    <div className='text-white'>

                        <div className='bg-transparent shadow-none gap-8 font-sans  p-6  text-gray-900 my-6  flex w-full'>
                                <Accordion />
                        </div>
                        <div className='flex bg-transparent text-white  w-full space-x-6 ml-4 pl-2'>
                            <div onClick={() => { SetIsOpen(true) }}  className="text-4xl hover:text-blue-700 active:bg-red-600"> + </div>
                            <Modal
                                    open={isOpen}
                                    close={closeModal}
                                    title={"Scrum Master FAQ"}
                                    fade={true}
                                    backdrop={true}
                                    fullscreen={"true"}
                                    centered={false}
                                    scrollable={true}
                                    transition={true}
                                
                                >
                                   <ScrumFAQ />
                                </Modal>

                            <div className='text-2xl m-2 pl-6'> Scrum Master FAQ </div>
                        </div>
                    </div>
                </>
            </section>

            <Footer />
        </>
    )
}

export default ServicePage;
