
import React, { useEffect, useContext, useState } from 'react'

import { navigate } from '@reach/router'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import HeaderTop from '../Header/HeaderTop'

import GlobalContext from '../../context/GlobalContext'
import AuthServices from '../../services/auth/AuthServices'



import Trial from './Trial/Trial'
import LightUser from './Individual/LightUser'
import HeavyUser from './Individual/HeavyUser'
import CorporateLargeUser from './Corporate/CorporateLargeUser'
import CorporateLightUser from './Corporate/CorporateLightUser'


import ScrumMasterPricing from './ScrumMasterPricing/ScrumTrail/ScrumMasterPricing'

import Intl from '../Intl'
import { data } from 'autoprefixer'

// import ScrumMasterPricing from './ScrumMasterPricing/ScrumMasterPricing'

// import Modal from '../Core/Modal/Modal'

// import GlobalContext from '../../context/GlobalContext'
// import { navigate } from '@reach/router'

// import GooglePayButton from '@google-pay/button-react'

// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"

// import { Modal } from 'bootstrap'


const PricingPage = () => {
    const gContext = useContext(GlobalContext);

    function QuerySelector() {
        // console.log("hello");
        navigate("/app/ContactUs/#enquiry");
    }


    // useEffect(() => {
    //     AuthServices.paymentIntegration()
    //     .then((res) => gContext.subscriptionPlans.current = res)
    //     .catch(err => console.log(err));
    // },[individual,corporate]);


    // useEffect(()=>{ 
    //     console.log("hello",subscirptionPlans);
    // },[subscirptionPlans]);




    // // Modal status
    // const [isOpen, SetIsOpen] = useState(false)

    // // Close Modal function
    // const closeModal = () => SetIsOpen(false)

    const [individual, setIndividual] = useState(true);
    const [corporate, setCorporate] = useState(false);

    const [fds, setFds] = useState(true);
    const [scrummaster, setScrumMaster] = useState(false);



    const [displayOption, setDisplayOption] = useState("fds");





    const handleChange = (e) => {
        setDisplayOption(e.target.value);
        // console.log("  HII  "+e.target.value)
    }

    const indiv = () => {
        setIndividual(true);
        setCorporate(false);
    }

    const corp = () => {
        setIndividual(false);
        setCorporate(true);
    }

    const fdsservice = () => {
        setFds(true);
        setScrumMaster(false);
    }

    const scrumservice = () => {
        setFds(false);
        setScrumMaster(true);
    }

    const active1 = "py-1 px-4 bg-cyan-600 text-gray-900 focus:outline-none"
    const disabled1 = "py-1 px-4  text-white focus:outline-none"

    const active2 = "py-2 px-4 bg-indigo-500 text-white focus:outline-none"
    const disabled2 = "py-2 px-4  text-white focus:outline-none"

    return (
        <div>
            <HeaderTop />
            <Header />

            <section className="text-gray-100 body-font overflow-hidden">
            <div className="flex mx-auto border-2 border-cyan-600 rounded overflow-hidden w-60 -ml-0 mt-6">

<button
    id="individual"
    name="individual"
    className={fds ? active1 : disabled1}
    onClick={fdsservice}
>
    {/* <Intl id="individual"></Intl> */}
    FDS Service
</button>

<button
    id="corporate"
    name="corporate"
    className={scrummaster ? active1 : disabled1}
    onClick={scrumservice}
>
    {/* <Intl id="corporate"></Intl> */}
    Scrum Master
</button>
</div>
                
                <section className="container px-4 py-4 mx-auto text-gray-900">
                    
                    <div className="flex flex-col text-center w-full">
                        <div className=" p-2">
                            <h1 className="sm:text-4xl text-6xl font-thin title-font  mb-2 text-gray-300">
                                <Intl id="easyReliableAffordable"></Intl>{" "}
                            </h1>
                            <p className="lg:w-2/3 mx-auto leading-relaxed font-medium text-base text-gray-300">
                                <Intl id="pricingExplanation"></Intl>
                            </p>
                        </div>
                      



                        {/* <div>
                                <select
                                    type='option'
                                    id='services'
                                    name='services'
                                    select
                                    className='w-full h-10 bg-white rounded border border-gray-300 focus:border-avermass-blue-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mt-10'
                                    autofocus
                                    autocomplete
                                    required
                                    onChange={handleChange}
                                >
                                    <option id='fds' name="fds"  value={"fds"} > For FDS Service 👇 </option>
                                    <option id='scrummaster'  name="scrummaster"   value={"scrummaster"}>For Scrum Master👇</option>
                                </select>

                            </div> */}






                    </div>

                    {
                        // displayOption === "fds" &&
                        fds &&
                        <div className='' >
                            <div className="flex mx-auto w-48 border-2 border-indigo-500 rounded overflow-hidden">

                                <button
                                    id="individual"
                                    name="individual"
                                    className={individual ? active2 : disabled2}
                                    onClick={indiv}
                                >
                                    <Intl id="individual"></Intl>
                                </button>

                                <button
                                    id="corporate"
                                    name="corporate"
                                    className={corporate ? active2 : disabled2}
                                    
                                    onClick={corp}
                                >
                                    <Intl id="corporate"></Intl>
                                </button>
                            </div>

                            <div className="flex flex-wrap m-4 justify-around">

                                <div className="p-4 xl:w-1/4 md:w-1/2 w-full ">
                                    <Trial />
                                </div>
                                <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                                    {individual &&
                                        <LightUser />
                                    }
                                    {corporate &&
                                        <CorporateLightUser />
                                    }
                                </div>
                                <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                                    {individual &&
                                        <HeavyUser />
                                    }
                                    {corporate &&
                                        <CorporateLargeUser
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        //  displayOption === "scrummaster" &&
                        scrummaster &&
                        <div className="flex flex-wrap justify-around">

                           
                                <ScrumMasterPricing />
                            
                        </div>
                    }
                </section>
            </section>
            <p className=" font-sans text-2xl text-gray-100 flext text-center m-6">
            For special offers, packages and to participate in product development write to us at <span
                    // onClick={()=> QuerySelector()}
                    activeClassName="border-b-2 border-gray-200"
                    className="font-sans text-2xl text-blue-accent-400 hover:text-indigo-800 cursor-pointer"
                >support@avermass.de </span>.
                
            </p>
            <Footer />
        </div>
    );
}

export default PricingPage
