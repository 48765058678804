import React from 'react';
import { useState, useContext } from 'react';
import Intl from '../../Intl';
import Modal from '../../Core/Modal/Modal';

import GlobalContext from '../../../context/GlobalContext';
import { navigate } from '@reach/router';
import PaymentModel from '../../Core/PaymentModel';

function HeavyUser() {
  const gContext= useContext(GlobalContext);
  const {subscriptionPlans} = useContext(GlobalContext);

  // Modal status
  const [isOpen, SetIsOpen] = useState(false);
  //console.log('Environment Variable', process.env.);

  // Close Modal function
  const closeModal = () => SetIsOpen(false);

  return (
    <>
      <div className='bg-blue-800 h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col  overflow-hidden'>
        <h2 className='text-sm tracking-widest title-font mb-1 font-medium'>
          {/* <Intl id="heavyuser"></Intl> */}
          {subscriptionPlans?.results[2]?.name}
        </h2>
        <h1 className='text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b-2 border-gray-900'>
          <span>
          {subscriptionPlans?.results[2]?.amount}
            </span>
          <span className='text-lg ml-1 font-normal text-gray-900'>/{subscriptionPlans?.results[0]?.duration}</span>
        </h1>

        {
          subscriptionPlans?.results[2]?.feature_list?.map( feature => {
            return (

                <p className='flex items-center text-gray-900 mb-2'>
                  <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
                    <svg
                      fill='none'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2.5'
                      className='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                      <path d='M20 6L9 17l-5-5'></path>
                    </svg>
                  </span>
                  {/* <Intl id="heavyuserfeaturefirst"></Intl> */}
                  {feature.name}
                </p>
            )
          })
        } 
        {/* <p className='flex items-center text-gray-900 mb-2'>
          <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
            <svg
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2.5'
              className='w-3 h-3'
              viewBox='0 0 24 24'
            >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>
          </span>
          <Intl id="heavyuserfeaturesecond"></Intl>
        </p>
        <p className='flex items-center text-gray-900 mb-2'>
          <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
            <svg
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2.5'
              className='w-3 h-3'
              viewBox='0 0 24 24'
            >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>
          </span>
          <Intl id="heavyuserfeaturethird"></Intl>
        </p>

        <p className='flex items-center text-gray-900 mb-6'>
          <span className='w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-700 text-white rounded-full flex-shrink-0'>
            <svg
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2.5'
              className='w-3 h-3'
              viewBox='0 0 24 24'
            >
              <path d='M20 6L9 17l-5-5'></path>
            </svg>
          </span>
          <Intl id="heavyuserfeaturefourth"></Intl>
        </p> */}
        <button
          onClick={() => {
            if (!gContext.isLoggedIn) {
              navigate('/app/login');
            } else {
              SetIsOpen(true);
            }
          }}
          className='flex items-center mt-auto text-black bg-gray-100 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 hover:text-gray-100 rounded transition ease-out'
        >
          <Intl id="buynow"></Intl>
          <svg
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            className='w-4 h-4 ml-auto'
            viewBox='0 0 24 24'
          >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>
        </button>

        {/* Payment model */}
        <PaymentModel open={isOpen} close={closeModal} title={'Choose your payment gateway'} amount={subscriptionPlans?.results[2]?.amount} />

        <p className='text-xs text-gray-900 mt-3'>
          {' '}
         <Intl id="heavyuserExplanation"></Intl>
        </p>
      </div>
    </>
  );
}

export default HeavyUser;
